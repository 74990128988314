
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, { Children } from 'react';
import checkedIcon from "../../../icons/icon/radio-fill-new.svg"
import uncheckedIcon from "../../../icons/icon/radio-empty.svg"



function RenderOptionComponent ({ name, cell, action }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const checked = data[cell] === action 

    let text = action + "";
    if (cell === "C10")
        text = text?.replaceAll(" e non ristrutturata di recente", "")
        ?.replaceAll(" o recentemente ristrutturata senza coibentazione", "")
        ?.replaceAll(" o ristrutturata con coibentazione", "")

    return <div className="d-flex  align-items-center select-option"
        onClick={() => dispatch(set({
            cell: cell,
            value: action
        }))}>
             <div className="mr-4" >
            <img src={checked ? checkedIcon : uncheckedIcon} alt={"Icona " + name} />
        </div>
        <div>
            <div className={"main " + (checked ? "bold" : "") }>{text.split("(")[0]}</div>
            {text.split("(")[1] && <div className="sub">{text.split("(")[1].replaceAll(")", "")}</div>}
        </div>
    </div>
}

export default RenderOptionComponent;
