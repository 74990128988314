import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyAvatar;

export const DsyAvatar = createComponent(
  React,
  'dsy-avatar',
  LitComp,
  {},
);