import React from "react"

export function GriCheckboxComponent({id, label, onChange, checked, disabled, ariaChecked, ariaLabel, name}) {
   
    return (<input id={id ?? ""} className="gri-checkbox-component" type="checkbox" onChange={onChange} 
        checked={checked}
        aria-checked={ariaChecked === true ? true : false}
        aria-label={ariaLabel ?? ""}
        disabled={disabled}
        onKeyDown={(e) => {
        if (e.key === "Enter" || e.key ==="Space" ) onChange(e)
    }} />)   
   
  } 

                  