import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyThemeProvider;

export const DsyReactThemeProvider = createComponent(
  React,
  'dsy-theme-provider',
  LitComp,
  {},
);
