// aria
import soldi_aria_unknown from "../../../icons/banner/soldi/aria/soldi-unknown.svg"
import soldi_aria_grey from "../../../icons/banner/soldi/aria/soldi-grey.svg"
import soldi_aria_1 from "../../../icons/banner/soldi/aria/soldi-1.svg"
import soldi_aria_2 from "../../../icons/banner/soldi/aria/soldi-2.svg"
import soldi_aria_3 from "../../../icons/banner/soldi/aria/soldi-3.svg"
import soldi_aria_4 from "../../../icons/banner/soldi/aria/soldi-4.svg"
import soldi_aria_5 from "../../../icons/banner/soldi/aria/soldi-5.svg"
import soldi_aria_6 from "../../../icons/banner/soldi/aria/soldi-6.svg"
import soldi_aria_7 from "../../../icons/banner/soldi/aria/soldi-7.svg"
import soldi_aria_8 from "../../../icons/banner/soldi/aria/soldi-8.svg"
import soldi_aria_9 from "../../../icons/banner/soldi/aria/soldi-9.svg"
import soldi_aria_10 from "../../../icons/banner/soldi/aria/soldi-10.svg"
import soldi_aria_11 from "../../../icons/banner/soldi/aria/soldi-11.svg"

//gpl
import soldi_gpl_unknown from "../../../icons/banner/soldi/gpl/soldi-unknown.svg"
import soldi_gpl_grey from "../../../icons/banner/soldi/gpl/soldi-grey.svg"
import soldi_gpl_1 from "../../../icons/banner/soldi/gpl/soldi-1.svg"
import soldi_gpl_2 from "../../../icons/banner/soldi/gpl/soldi-2.svg"
import soldi_gpl_3 from "../../../icons/banner/soldi/gpl/soldi-3.svg"
import soldi_gpl_4 from "../../../icons/banner/soldi/gpl/soldi-4.svg"
import soldi_gpl_5 from "../../../icons/banner/soldi/gpl/soldi-5.svg"
import soldi_gpl_6 from "../../../icons/banner/soldi/gpl/soldi-6.svg"
import soldi_gpl_7 from "../../../icons/banner/soldi/gpl/soldi-7.svg"
import soldi_gpl_8 from "../../../icons/banner/soldi/gpl/soldi-8.svg"
import soldi_gpl_9 from "../../../icons/banner/soldi/gpl/soldi-9.svg"
import soldi_gpl_10 from "../../../icons/banner/soldi/gpl/soldi-10.svg"
import soldi_gpl_11 from "../../../icons/banner/soldi/gpl/soldi-11.svg"

//gasolio
import soldi_gasolio_unknown from "../../../icons/banner/soldi/gasolio/soldi-unknown.svg"
import soldi_gasolio_grey from "../../../icons/banner/soldi/gasolio/soldi-grey.svg"
import soldi_gasolio_1 from "../../../icons/banner/soldi/gasolio/soldi-1.svg"
import soldi_gasolio_2 from "../../../icons/banner/soldi/gasolio/soldi-2.svg"
import soldi_gasolio_3 from "../../../icons/banner/soldi/gasolio/soldi-3.svg"
import soldi_gasolio_4 from "../../../icons/banner/soldi/gasolio/soldi-4.svg"
import soldi_gasolio_5 from "../../../icons/banner/soldi/gasolio/soldi-5.svg"
import soldi_gasolio_6 from "../../../icons/banner/soldi/gasolio/soldi-6.svg"
import soldi_gasolio_7 from "../../../icons/banner/soldi/gasolio/soldi-7.svg"
import soldi_gasolio_8 from "../../../icons/banner/soldi/gasolio/soldi-8.svg"
import soldi_gasolio_9 from "../../../icons/banner/soldi/gasolio/soldi-9.svg"
import soldi_gasolio_10 from "../../../icons/banner/soldi/gasolio/soldi-10.svg"
import soldi_gasolio_11 from "../../../icons/banner/soldi/gasolio/soldi-11.svg"

//legna
import soldi_legna_unknown from "../../../icons/banner/soldi/legna/soldi-unknown.svg"
import soldi_legna_grey from "../../../icons/banner/soldi/legna/soldi-grey.svg"
import soldi_legna_1 from "../../../icons/banner/soldi/legna/soldi-1.svg"
import soldi_legna_2 from "../../../icons/banner/soldi/legna/soldi-2.svg"
import soldi_legna_3 from "../../../icons/banner/soldi/legna/soldi-3.svg"
import soldi_legna_4 from "../../../icons/banner/soldi/legna/soldi-4.svg"
import soldi_legna_5 from "../../../icons/banner/soldi/legna/soldi-5.svg"
import soldi_legna_6 from "../../../icons/banner/soldi/legna/soldi-6.svg"
import soldi_legna_7 from "../../../icons/banner/soldi/legna/soldi-7.svg"
import soldi_legna_8 from "../../../icons/banner/soldi/legna/soldi-8.svg"
import soldi_legna_9 from "../../../icons/banner/soldi/legna/soldi-9.svg"
import soldi_legna_10 from "../../../icons/banner/soldi/legna/soldi-10.svg"
import soldi_legna_11 from "../../../icons/banner/soldi/legna/soldi-11.svg"

//metano
import soldi_metano_unknown from "../../../icons/banner/soldi/metano/soldi-unknown.svg"
import soldi_metano_grey from "../../../icons/banner/soldi/metano/soldi-grey.svg"
import soldi_metano_1 from "../../../icons/banner/soldi/metano/soldi-1.svg"
import soldi_metano_2 from "../../../icons/banner/soldi/metano/soldi-2.svg"
import soldi_metano_3 from "../../../icons/banner/soldi/metano/soldi-3.svg"
import soldi_metano_4 from "../../../icons/banner/soldi/metano/soldi-4.svg"
import soldi_metano_5 from "../../../icons/banner/soldi/metano/soldi-5.svg"
import soldi_metano_6 from "../../../icons/banner/soldi/metano/soldi-6.svg"
import soldi_metano_7 from "../../../icons/banner/soldi/metano/soldi-7.svg"
import soldi_metano_8 from "../../../icons/banner/soldi/metano/soldi-8.svg"
import soldi_metano_9 from "../../../icons/banner/soldi/metano/soldi-9.svg"
import soldi_metano_10 from "../../../icons/banner/soldi/metano/soldi-10.svg"
import soldi_metano_11 from "../../../icons/banner/soldi/metano/soldi-11.svg"

//pellet
import soldi_pellet_unknown from "../../../icons/banner/soldi/pellet/soldi-unknown.svg"
import soldi_pellet_grey from "../../../icons/banner/soldi/pellet/soldi-grey.svg"
import soldi_pellet_1 from "../../../icons/banner/soldi/pellet/soldi-1.svg"
import soldi_pellet_2 from "../../../icons/banner/soldi/pellet/soldi-2.svg"
import soldi_pellet_3 from "../../../icons/banner/soldi/pellet/soldi-3.svg"
import soldi_pellet_4 from "../../../icons/banner/soldi/pellet/soldi-4.svg"
import soldi_pellet_5 from "../../../icons/banner/soldi/pellet/soldi-5.svg"
import soldi_pellet_6 from "../../../icons/banner/soldi/pellet/soldi-6.svg"
import soldi_pellet_7 from "../../../icons/banner/soldi/pellet/soldi-7.svg"
import soldi_pellet_8 from "../../../icons/banner/soldi/pellet/soldi-8.svg"
import soldi_pellet_9 from "../../../icons/banner/soldi/pellet/soldi-9.svg"
import soldi_pellet_10 from "../../../icons/banner/soldi/pellet/soldi-10.svg"
import soldi_pellet_11 from "../../../icons/banner/soldi/pellet/soldi-11.svg"

//elettrico
import soldi_elettrico_unknown from "../../../icons/banner/soldi/elettrico/soldi-unknown.svg"
import soldi_elettrico_grey from "../../../icons/banner/soldi/elettrico/soldi-grey.svg"
import soldi_elettrico_1 from "../../../icons/banner/soldi/elettrico/soldi-1.svg"
import soldi_elettrico_2 from "../../../icons/banner/soldi/elettrico/soldi-2.svg"
import soldi_elettrico_3 from "../../../icons/banner/soldi/elettrico/soldi-3.svg"
import soldi_elettrico_4 from "../../../icons/banner/soldi/elettrico/soldi-4.svg"
import soldi_elettrico_5 from "../../../icons/banner/soldi/elettrico/soldi-5.svg"
import soldi_elettrico_6 from "../../../icons/banner/soldi/elettrico/soldi-6.svg"
import soldi_elettrico_7 from "../../../icons/banner/soldi/elettrico/soldi-7.svg"
import soldi_elettrico_8 from "../../../icons/banner/soldi/elettrico/soldi-8.svg"
import soldi_elettrico_9 from "../../../icons/banner/soldi/elettrico/soldi-9.svg"
import soldi_elettrico_10 from "../../../icons/banner/soldi/elettrico/soldi-10.svg"
import soldi_elettrico_11 from "../../../icons/banner/soldi/elettrico/soldi-11.svg"

//lampadine
import bulb_off from "../../../icons/banner/lampadine/lampadina-off.svg"
import bulb_on from "../../../icons/banner/lampadine/lampadina-on.svg"
import bulb_unknown from "../../../icons/banner/lampadine/lampadina-unknown.svg"

//lampadine group
export const lampadine = {
    on: bulb_on,
    off: bulb_off,
    unknown: bulb_unknown
}

//soldi
export const soldi = {
    aria: {
        unknown: soldi_aria_unknown,
        grey: soldi_aria_grey,
        1: soldi_aria_1,
        2: soldi_aria_2,
        3: soldi_aria_3,
        4: soldi_aria_4,
        5: soldi_aria_5,
        6: soldi_aria_6,
        7: soldi_aria_7,
        8: soldi_aria_8,
        9: soldi_aria_9,
        10: soldi_aria_10,
        11: soldi_aria_11,
    },
    gasolio: {
        unknown: soldi_gasolio_unknown,
        grey: soldi_gasolio_grey,
        1: soldi_gasolio_1,
        2: soldi_gasolio_2,
        3: soldi_gasolio_3,
        4: soldi_gasolio_4,
        5: soldi_gasolio_5,
        6: soldi_gasolio_6,
        7: soldi_gasolio_7,
        8: soldi_gasolio_8,
        9: soldi_gasolio_9,
        10: soldi_gasolio_10,
        11: soldi_gasolio_11
    },
    metano: {
        unknown: soldi_metano_unknown,
        grey: soldi_metano_grey,
        1: soldi_metano_1,
        2: soldi_metano_2,
        3: soldi_metano_3,
        4: soldi_metano_4,
        5: soldi_metano_5,
        6: soldi_metano_6,
        7: soldi_metano_7,
        8: soldi_metano_8,
        9: soldi_metano_9,
        10: soldi_metano_10,
        11: soldi_metano_11
    },
    gpl: {
        unknown: soldi_gpl_unknown,
        grey: soldi_gpl_grey,
        1: soldi_gpl_1,
        2: soldi_gpl_2,
        3: soldi_gpl_3,
        4: soldi_gpl_4,
        5: soldi_gpl_5,
        6: soldi_gpl_6,
        7: soldi_gpl_7,
        8: soldi_gpl_8,
        9: soldi_gpl_9,
        10: soldi_gpl_10,
        11: soldi_gpl_11
    },
    legna: {
        unknown: soldi_legna_unknown,
        grey: soldi_legna_grey,
        1: soldi_legna_1,
        2: soldi_legna_2,
        3: soldi_legna_3,
        4: soldi_legna_4,
        5: soldi_legna_5,
        6: soldi_legna_6,
        7: soldi_legna_7,
        8: soldi_legna_8,
        9: soldi_legna_9,
        10: soldi_legna_10,
        11: soldi_legna_11
    },
    pellet: {
        unknown: soldi_pellet_unknown,
        grey: soldi_pellet_grey,
        1: soldi_pellet_1,
        2: soldi_pellet_2,
        3: soldi_pellet_3,
        4: soldi_pellet_4,
        5: soldi_pellet_5,
        6: soldi_pellet_6,
        7: soldi_pellet_7,
        8: soldi_pellet_8,
        9: soldi_pellet_9,
        10: soldi_pellet_10,
        11: soldi_pellet_11
    },
    elettrico: {
        unknown: soldi_elettrico_unknown,
        grey: soldi_elettrico_grey,
        1: soldi_elettrico_1,
        2: soldi_elettrico_2,
        3: soldi_elettrico_3,
        4: soldi_elettrico_4,
        5: soldi_elettrico_5,
        6: soldi_elettrico_6,
        7: soldi_elettrico_7,
        8: soldi_elettrico_8,
        9: soldi_elettrico_9,
        10: soldi_elettrico_10,
        11: soldi_elettrico_11,
    },

}