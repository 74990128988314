import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyCheckbox;

export const DsyCheckbox = createComponent(
  React,
  'dsy-checkbox',
  LitComp,
  {},
);

export default DsyCheckbox;
