import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyRadioGroup;

export const DsyRadioGroup = createComponent(
  React,
  'dsy-radio-group',
  LitComp,
  {},
);

export default DsyRadioGroup;
