import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyTooltip;

export const DsyTooltip = createComponent(
  React,
  'dsy-tooltip',
  LitComp,
  {},
);

export default DsyTooltip;
