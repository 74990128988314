import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyToast;

export const DsyToast = createComponent(
    React,
    "dsy-toast",
    LitComp,
    {}
)