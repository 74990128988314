
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, { useEffect } from 'react';
import SliderWithThumbComponent from '../slider/SliderWithThumbComponent';
import InfoButtonComponent from '../info-button/InfoButtonComponent';
import climaAi from "../../../utils/climaai.json"
import SelectButtonComponent from '../dropdown/SelectButtonComponent';
import AumentoContatoreComponent from './AumentoContatoreComponent';
import { GriToggleComponent } from '../../dsy-inhouse/GriToggleComponent';
import { DsyDivider } from '../../../dsy-wrappers';


function OptionalComponent({ name, cell, min, max, step, pre, 
    response, disclaimer,choices, extraClass, 
    info,forceValue,
    cellSiNo, numbertype, optionsDriver, unit, box }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    let currentValue = undefined

    const moveArrow = (e) => {
        const range = e.target;
        const value = range.value;
        const min = range.min || 0;
        const max = range.max || 100;
        let newVal = Number(((value - min) * 100) / (max - min));
        if (newVal < 0) newVal = 0;
        if (newVal > 100) newVal = 100;
        const thumb = range.closest(".sliderwiththumb").querySelector(".thumb");
        const fill = range.closest(".sliderwiththumb").querySelector(".fill");
        fill.style.width = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        if (newVal < 15)
            thumb.style.left = `calc(${newVal}% + (${8  - newVal * 0.15}px))`;    
        else if (newVal > 94)
            thumb.style.left = `calc(${newVal}% + (${-20 - newVal * 0.15}px))`;
        else    
            thumb.style.left = `calc(${newVal}% + (${0 - newVal * 0.15}px))`;
    }


    switch (cell){
        case "C16":
            currentValue =  Math.min(parseInt(data[cell]), 9)
            break;
    
        default:
            currentValue = data[cell]
    }

    if (cell === "NumeroClimatizzatori" && (data["NumeroClimatizzatori"] === undefined|| data["NumeroClimatizzatori"] === null)){
        dispatch(set({
            cell: "NumeroClimatizzatori",
            value: currentValue
        }))
    }
    
    if (currentValue === "No") currentValue = false;

    let suggestedValue
    let shouldRender = (cellSiNo !== undefined && data[cellSiNo] === true)
    if (cellSiNo === undefined) shouldRender= true; 
    if (cell === "NumeroClimatizzatori") shouldRender =!( currentValue == false || currentValue == undefined || currentValue == null)

    if (optionsDriver === "NumeroClimatizzatori" && (data["NumeroClimatizzatori"] === false || data["NumeroClimatizzatori"] === undefined || data["NumeroClimatizzatori"] === "No")){
        suggestedValue = optionsDriver ? 
            choices.filter(x => x.N == Math.min(parseInt(data["C16"]), 9)).map(x => x.name)[0]
            : data[response]
    }
    else {
        if (optionsDriver)
            suggestedValue = choices.filter(x => x.N == data[optionsDriver]).map(x => x.name)[0]
        else if (response === "C16")
            suggestedValue = Math.min(parseInt(data["C16"]), 9)
        else        
            suggestedValue = data[response]
    }

    const getChoices = () => { 
        if (data[optionsDriver] !== "No" && data[optionsDriver] !== false) 
            return choices.filter(x => x.N == data[optionsDriver]).map(x => x.name) 
        else 
            return choices.filter(x => x.N == Math.min(parseInt(data["C16"]), 9)).map(x => x.name)
    }

    const cellSiNoHandler = (e) => {
        console.log(e)
        const currentVal = data[cellSiNo]
        const slider = document.querySelector(".slider" + cell + " input")                            
        if (currentVal) // need to remove value
        {
            dispatch(set({
                cell: cell,
                value: undefined
            }))
            
            if(slider){
                slider.value = 0
                moveArrow({target: slider})
            }
        }
        else{
            if (cell === "C42"){
            dispatch(set({
                cell: cell,
                value: suggestedValue
            }))
            dispatch(set({
                cell: "ModificaConfigurazione",
                value: true
            }))

            if(slider){
                slider.value = suggestedValue
                moveArrow({target: slider})
            }
               
        }
            if (cell === "D48_val"){
                dispatch(set({
                    cell: cell,
                    value: "59.2cm"
                }))
            }
        } 
        
        dispatch(set({
            cell: cellSiNo,
            value: !data[cellSiNo]
        }))
    }

    const noCellSiNoHandler = (e) => {
        console.log(e)
        if (cell === "NumeroClimatizzatori" && (currentValue === false || currentValue === undefined)) {
            dispatch(set({
                cell: cell,
                value: suggestedValue
            }))
            // dispatch(set({
            //     cell: "ModificaConfigurazione",
            //     value: true
            // }))
            const slider = document.querySelector(".slider" + cell + " input")                            
            if(slider){
                slider.value = suggestedValue
                moveArrow({target: slider})
            }
        }
        else {
            dispatch(set({
                cell: cell,
                value: !currentValue
            }))
            dispatch(set({
                cell: "ModificaConfigurazione",
                value: undefined
            }))
            const slider = document.querySelector(".slider" + cell + " input")                            
            if(slider){
                slider.value = 1
                moveArrow({target: slider})
            }
    }}

    const from = data["D61"] ?? 3
    const to = (data["C41"] === "Si" || data["C41"] === true) ? data["C82"] : from

    return (<>
        {box && <AumentoContatoreComponent from={from} to={to} extraClass={data[cellSiNo] === true ? "disa" : ""} />}
        <div className={"slider-component d-flex flex-column " + (extraClass !== undefined ? extraClass:""  )}>
            {pre && <><h2 className={(pre !== " " ? "mt-16": "")+ " pre"  + (shouldRender === true ? " disabled" : "")}>{pre}</h2>
            <div className={"mb-16 mt-16" + (shouldRender === true ? " disabled" : "")}><b >{suggestedValue} {unit}</b></div> </>}
            {cell && <>
            <div className={"slider-component-switch d-flex flex-row flex-row align-items-center justify-content-between  my-3 "}>
                <label htmlFor={"id-"+name} className={shouldRender === true ? " bold" : ""}>{name}</label>
                <div className="me-md-2 d-flex justify-content-center align-items-center">
                    { cellSiNo ?
                    <GriToggleComponent
                        id={"id-"+name}
                        name={name}
                        ariaChecked={data[cellSiNo] === true}
                        checked={data[cellSiNo] === true}
                        onChange={cellSiNoHandler} />
                :
                <GriToggleComponent
                id={"id-"+name}
                name={name}
                ariaChecked={(currentValue == "No" ||currentValue == false || currentValue == undefined ||  currentValue == null)  ? false : true}
                checked={(currentValue == "No" ||currentValue == false || currentValue == undefined ||  currentValue == null) ? false : true}
                onChange={noCellSiNoHandler} />
                    }
                </div>
                <div id="liveRegion" 
                    aria-live="polite" aria-atomic="true" 
                    className="visually-hidden">Switch per {name} attualmente {currentValue ? "Attivato " : "Non Attivato "}</div>
            </div> 
             {/* {currentValue !== false && currentValue !== "No" && choices === undefined && shouldRender &&  */}
             { data[cellSiNo] === true && choices === undefined && 
                <SliderWithThumbComponent 
                cell={cell}
                extraClass={!shouldRender ? "disabled mt-16" : "mt-16"}
                min={min}
                max={max} 
                unit={unit}
                step={step}
                name={name}
                moveArrow={moveArrow}
                disabled={!shouldRender}
                currentValue={currentValue}
                forceValue={forceValue}
                setCurrentValue={(value) =>{ 
                    if (shouldRender){
                        dispatch(set({
                                cell: cell,
                                value: value
                            })) 
                    if (cellSiNo) dispatch(set({
                        cell: cellSiNo,
                        value: true
                    }))
                }
                }
            } 
            />
            }

            {currentValue !== false &&  choices && shouldRender &&<div className="d-flex align-items-center winfo">
                <SelectButtonComponent 
                    extraClass={"flex-grow-1"} 
                    name={"Configurazione"} 
                    choices={optionsDriver ? getChoices() : choices} 
                    cell={cell} />
                {info && <InfoButtonComponent extraClass="mw40" {...info} />    }
                </div>
            }
            </>}
           
            { disclaimer && <div className="mt-16 mb-16 small-text">{disclaimer}</div> }

         
        </div>
        <div className="mt-3 mb-2" aria-hidden="true"><DsyDivider /></div>
            <p className="small-text">
            * Per i contributi una tantum vengono considerati valori medi che includono un costo amministrativo fisso di 25,30€ e un contributo di 61,78€ per ogni kW aggiuntivo fino a 6kW oppure 78,14€ per ogni kW aggiuntivo oltre i 6kW. Per l'aumento dei costi ricorrenti in bolletta, si stima un incremento mensile di 3,00€ per ogni kW aggiuntivo. I costi indicati includono l'IVA.
            </p>
        </>
    );
}



export default OptionalComponent;