import React, { useState, useEffect, useRef } from 'react';
import Header from './form/layout/Header.jsx';
import FormPage from './form/FormPage';
import NextButton from "./form/layout/NextButtonComponent.jsx"
import { useDispatch, useSelector } from 'react-redux';
import {
    get, set
} from '../reducers/data';
import WaitAndThanks from './form/layout/WaitAndThanks.jsx';
import formTemplate from "../utils/form.js"
import utils from "../utils/index.js"
import RisparmioFinaleComponent from "./form/final/RisparmioFinaleDesktopComponent"
import { useNavigate } from "react-router-dom";
import Thanks from './Thanks.jsx';
const testRendering = utils.testRendering

function FormEnelDesktop() {
  const navigate = useNavigate();
  const data = useSelector(get);
  const dispatch = useDispatch();
  let pageIndex = data["pageIndex"] || 0
  const anchor = useRef(null)
  const main = useRef(null)
  const setPageIndex = (index) => {
    dispatch(set({ cell: "pageIndex", value: index }))
    setPageChanged(true)
  }
  let screen = data["screen"] || 1
  const setScreen = (index) => {
    dispatch(set({ cell: "screen", value: index }))
    setPageChanged(true)
    setTimeout(() => {
        anchor?.current?.focus()
      }, 100)
  }
    const [pageChanged, setPageChanged] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      main?.current.focus();
      main.current.querySelector("input")?.focus() 
    }, [screen])

    useEffect(()=> {
      if(pageChanged){
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = (ev)=> {
            window.history.go(1);
            if (screen > 1) return changeScreen(screen - 1)
            else if (pageIndex > 0) changePage(pageIndex - 1, true)
            else navigate("/")
        };
        window.enel_data = {
          page: {
              pageInfo: {
                  pageName: "Dati abitazione",
                  pageURL: "https://simulatore-risparmio-energia.enelxstore.com/form#page=1"
              }
          }
        };
        window?._satellite?.track("pageview")
        setPageChanged(true)
      }
    }, [pageIndex])

    const changePage = (index, back) => {
      if (back === true) {
        const prevPage = formTemplate.pages[pageIndex -1]
        let items = prevPage.items.filter(x => shouldRender(x, data))
        if (items.length > 0) {
          const max = Math.max(...items.map(x => x.desktopPage))
          setScreen(max)
        }
      }
      setPageIndex(index)
      window.enel_data = { page: { pageInfo: {}}};
      window.enel_data.page.pageInfo.pageName = formTemplate.pages[index]?.title;
      window.enel_data.page.pageInfo.pageURL = "https://simulatore-risparmio-energia.enelxstore.com/form#page=" + (index + 1);
      window._satellite.track("pageview")
      dispatch(set({
        cell:"pageIndex",
        value: index
      }))
    }

    if (pageIndex === -1)
      return <Thanks error={`Sfortunamente l'indirizzo: <br /> <b> ${data["C14"]} ${data["CAP"]} </b> <br /> non è coperto dai servizi di elettrificazione.`} 
        retry={true}
        main={main}
        enelstore={false} />

    if (pageIndex === 100) {
      if (data["products"] && data["products"].length > 0) 
          return <RisparmioFinaleComponent main={main} />
      else 
          return <Thanks error={"Il livello di efficienza energetica della tua casa parla chiaro: hai fatto un lavoro straordinario! I tuoi sforzi hanno portato a risultati eccellenti, la tua casa è moderna e gestisce al meglio i consumi energetici. Come sempre, c'è margine di miglioramento! Dai un’occhiata al nostro sito per scoprire cos’altro puoi fare."} main={main} />
    }

    const shouldRender = (item) => {
      if (!item.conditionalRendering)
          return true
      else 
          return testRendering(item, data)
    }

    const copyToRedux = (data, res) => {
      const skip = ["C24", "C19","C18", "C28", "C29", "C34"]
      for (let value in res) {
        if (skip.includes(value)) continue;
          dispatch(
            set({
              cell: value,
              value: res[value]
            })
          )
        }
      }

  const clearInterventi = () => {
    ["C41","C45", "C48", "C53"].forEach(x => dispatch(set({ cell: x, value: false })));
  }

    const changePageClick = async () => {
        if (page.onSubmit) {
            setIsFinished(true);
            setIsLoading(true);
            const res = await page.onSubmit(data);
            if (res.products) {
                dispatch(set({
                  cell: "products",
                  value: res.products
                }))
        
                copyToRedux(data, {
                  ...res.data
                })
                clearInterventi()
                setIsLoading(false);
                // setTimeout(() => {
                    setIsFinished(false);
                    changePage(100);
                // }, 3000)
              }
              else {
                // if (res.C48 === "No" || res.C48 === false)
                //   res.ShowC48 = false;
                copyToRedux(data, res)
                clearInterventi()
                setIsLoading(false);
                setIsFinished(false);
                changePage(pageIndex + 1);
                setScreen(1);
              }      
        }
        else {
          if (page.onCheck){
             const res = page.onCheck(data)
             if (res === true) {
               window.location.href = "https://www.enelxstore.com/it/it/simulatore-impianto-fotovoltaico?ecid=Referral-SimulatoreElettrificazione-null-null-null"
               return;
             }
          }
          setScreen(1)
          changePage(pageIndex + 1)
        }
      }

    const changeScreen = async (index) => {
        if (index === 2 && page.items.find(x => x.type === "address")){
          const CAP_result = await utils.checkCAP(data["CAP"])
          if (CAP_result === false){
            setPageIndex(-1)
            return;
          }
        }

        let items = page?.items.filter(x => x.desktopPage === index && shouldRender(x))
        while(items.length === 0 || index > 10) {
            index > screen ? index++ : index--
            if (index >= lastScreen)
                return changePageClick()
            else
                items = page?.items.filter(x => x.desktopPage === index && shouldRender(x))
        }
        setScreen(index)
    }

    const page = formTemplate.pages.filter(p => shouldRender(p))[pageIndex || 0]
    
    if (page === undefined || page.items === undefined)  
      return <RisparmioFinaleComponent main={main} />

    const array = page?.items.map(x => x.desktopPage).filter(x => x !== undefined && !isNaN(x))
    const lastScreen = Math.max(...array)
    const buttonEnabled = page?.items.filter(x =>x.desktopPage === screen).reduce((prev, curr) => {
        
        if (prev === false) return false
        if (curr.cell === "D48") return true
        if (curr.type === "label" || curr.type === "spacer" || curr.type === "divider" || curr.type === "facsimile" || curr.type === "boxfotovirtuale" || curr.type === "gasdisclaimer" || curr.type === "small-note") return true
        if (!shouldRender(curr)) return true
        if (curr.cell === "D84" && data["C84"] !== true) return true
        if (curr.cell === "D48_val" && (data["C48"] === false || data["C48"] === undefined || data["C84"] === "")) return true
        if (curr.type ==="percentage"){
            if (data["C31"] === "No") return true
            else {
                const riscAmbiente = data['C25']
                const riscAcqua = data['C26']
                const pianoCottura = data['C27']
              
                const fieldsTotal = [
                  {
                    name: 'Elettrico',
                    cell: 'C33'
                  },
                  {
                    name: riscAmbiente,
                    cell: 'F25'
                  },
                  {
                    name: riscAcqua,
                    cell: 'F26'
                  },
                  {
                    name: pianoCottura,
                    cell: 'F27'
                  }
                ]
                const fields = []
                for (let field of fieldsTotal) {
                  if (!field.name) continue
                  if (!fields.map(x => x.name).includes(field.name)) fields.push(field)
                }

                for (let field of fields) {
                  if (!data[field.cell] || data[field.cell] < 0) return false
                }
                return true;
            }
        }

        if (curr.type === "address" && data["C14"]) return true

        if (curr.type === "multichoice"){
          if (curr.required === false) return true
          let choices = curr.choices.filter(x => shouldRender(x))
          if (choices.length === 0) return true
          return choices.filter(x => data[x.cell] === true || data[x.cell] === "Si").length > 0
        }

        if (curr.type === "slider" && curr.cellSiNo){
          if (data[curr.cellSiNo] === "No") return true
        }
        if ((curr.type === "slider" || curr.type === "stepper") && curr.max){
          if (data[curr.cell] === undefined || data[curr.cell] > curr.max) return false
        }
        if ((curr.type === "slider" || curr.type === "stepper") && curr.min){
          if (data[curr.cell] === undefined || data[curr.cell] < curr.min) return false
        }
        

        if (curr.type === "riscaldamento") {
          return true;
        }

        if (curr.required !== false && (data[curr.cell] === "" || data[curr.cell] === undefined)) {
            return false
        }

        return true;
    }, true)


    if (isFinished)
      // d-none d-lg-flex 
        return <div className="main d-flex row white-bg">
            <Header 
                    imageId="casa-magica"
                    className="col-12 col-lg-5" 
                    title={isLoading ? "Capiamo quanta energia usi in un anno!" : page.title} 
                    subtitle={isLoading ? "" :page.subtitle} 
                    page={page.page ?? pageIndex} /> 
            <main ref={main} className='form-slide thanks d-flex col-lg-7 flex-column justify-content-space-between align-items-space-between'>
                <WaitAndThanks loading={isLoading} fs={pageIndex === 100} />
            </main>
        </div>;

    return <div className="main d-flex row">
        <Header  className={"col-12 col-lg-5 col-xxl-4"} 
                anchor={anchor}
                main={main}
                title={page.title} 
                subtitle={page.subtitle} 
                imageId={page.image}
                page={page.page ?? pageIndex} />
        {/* <div className="d-none d-lg-flex col-lg-1 "></div> */}
        <main ref={main} className='form-slide d-flex col-lg-7 col-xxl-8 flex-column align-items-space-between' id="main">
            <FormPage 
              riepilogo={pageIndex !== 0 && pageIndex !== 3}
              items={page.items.filter(x => x.desktopPage === screen)} />
            <NextButton 
                      buttonEnabled={!buttonEnabled} 
                      onlyContinue={page.showBack}
                      // extra={pageIndex === 3 ? <SummaryExtraButton url={data["ProjectUrl"]} /> : null}
                      onBack={screen > 1 ? () => changeScreen(screen - 1) :
                              pageIndex > 0 ? () => changePage(pageIndex - 1, true) : 
                              () => { navigate("/")} }
                      text={page.nextText ? page.nextText : 'Avanti'}
                      onClick={() => changeScreen(screen + 1)} />
        </main>
        {/* <div className="d-none d-xxl-block col-lg-1 col-xxl-1"></div> */}
    </div>;
}

// function SummaryExtraButton ({url}) {
//   return (
//     <div className="d-flex align-items-center col-lg-4 col-xxl-5">
//       <div className="f12 d-lg-none d-xxl-block" style={{ flexGrow:0}}>
//       Se continui potrai personalizzare le soluzioni che abbiamo proposto e scoprire i costi degli interventi.
//       </div>
//       <div style={{ flexGrow: 1, marginRight: "1rem", marginLeft: "1rem", flexShrink: 0, maxWidth: "304px"}}>
//         <a href={url} target='_blank' rel="noreferrer">
//         <DsyButton fullWidth={true} variant="secondary" shape="square" >Scarica la tua stima di risparmio</DsyButton>
//         </a>
//       </div>
//   </div>)
// }

export default FormEnelDesktop;