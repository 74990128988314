import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsySingleCardSelect;

export const DsySingleCardSelect = createComponent(
  React,
  'dsy-single-card-select',
  LitComp,
  {},
);

export default DsySingleCardSelect;
