
import React, {useState} from 'react';
import RiepilogoModalComponent from '../riepilogo/RiepilogoModalComponent.jsx';
import {
  get, set
} from '../../../reducers/data.js';
import u from '../../../utils/index.js'
import { useDispatch, useSelector } from 'react-redux';
import { GriButton } from '../../dsy-inhouse/GriButtonComponent.jsx';

function NextButtonComponent({ buttonEnabled, onClick, onBack, text, onlyContinue, extraClass, extra }) {
  const [showRiepilogo, setShowRiepilogo] = useState(false);
  const data = useSelector(get);
  let isGoingToFotoV = u.sendFoto(data)
  if (onlyContinue === false)
    return   <div className="next-button-box no-border d-flex justify-content-center centered justify-content-lg-end">
                {extra && <>{extra}</>}
                <div style={{ width: "244px"}}>
                  <GriButton shape="square" 
                            id="continue"
                            full-width={true}
                            role="button"
                            aria-roledescription="bottone"
                            disabled={false}
                            className="single-next"
                            title={text === "Avanti" ? "Vai avanti" : text}
                            label={text === "Avanti" ? "Vai avanti" : text} 
                            onClick={onClick}>{text}</GriButton>
                </div>
              </div>
  return (<>
    <div aria-live={"assertive"} className="text-center">
      {isGoingToFotoV ? "Premendo Avanti si apre una nuova scheda. Verrai indirizzato al simulatore Enel, dedicato al fotovoltaico": ""}</div>
      <div className={"px-3 next-button-box d-flex justify-content-between d-md-flex justify-content-md-center justify-content-lg-end " + extraClass ?? "" }> {/* messo lg-end invece de lg-between */}
        <div onClick={() => setShowRiepilogo(true)} className="riepilogo-label d-none"><span>Riepilogo</span></div> {/* d-lg-block */}
        <RiepilogoModalComponent show={showRiepilogo} close={() => setShowRiepilogo(false)} />
        {extra && <>{extra}</>}
        {onBack !== undefined &&
         <GriButton icon-side="left" 
                    icon="thin-arrow-left-secondary"  
                    shape="square" 
                    variant="link" 
                    linkConfig={{}}
                    label='Torna alla pagina precedente' 
                    title={"Torna Indietro"} 
                    id="back-button"
                    className="d-lg-none" 
                    icon-size="16px"
                    disabled={(onBack === undefined) ? true : false} 
                    onClick={onBack}>Indietro</GriButton>} 
        <div className="d-flex">
          <GriButton 
              icon="thin-arrow-left-secondary"  
              shape="square" 
              id="back-button2"
              variant="secondary" 
              label='Torna alla pagina precedente'
              title={"Torna Indietro"} 
              className="d-none d-lg-block me-3" 
              disabled={(onBack === undefined) ? true : false} 
              onClick={onBack}>
                Indietro</GriButton>
          <div id="next-button">
          <GriButton 
                    shape="square" 
                    id="next-button"
                    full-width={true} 
                    label={text === "Avanti" ? "Vai alla pagina successiva" : text} 
                    title={text === "Avanti" ? "Vai alla pagina successiva" : text} 
                    disabled={(buttonEnabled === true || buttonEnabled === undefined) ? true : false} 
                    onClick={onClick}>{text}</GriButton>
          </div>
        </div>
      </div>
      </>);
}

export default NextButtonComponent;