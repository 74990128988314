import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyChart;

export const DsyChart = createComponent(
  React,
  'dsy-chart',
  LitComp,
  {},
);
