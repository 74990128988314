import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyInputDropdown;

export const DsyInputDropdown = createComponent(
  React,
  'dsy-input-dropdown',
  LitComp,
  {},
);

export default DsyInputDropdown;
