
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, { Children } from 'react';
import { Modal, Container} from 'react-bootstrap';
import RenderOptionButtonComponent from './RenderOptionButtonComponent';
import closeIcon from "../../../icons/icon/CloseModal.svg"
import {  DsyDivider } from '../../../dsy-wrappers';
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';


function SelectPopupButtonsComponent({ name, cell, choices, close, show, chiudi }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const showName = name.includes("100%") ? "Offerta 100% Rinnovabili" : name

    const closeCopy = ["C25", "C26", "C27"].includes(cell) ? "Chiudi" : "Annulla"
    const nonLoSoChoices = [ { key: "Conosco " + name, label: "Conosco " + name }, { key: "Non so", label: "Non so" }]
    return (
        <Modal 
            keyboard={true}
            autoFocus={true}
            show={show} 
            scrollable={true}
            centered={true}
            role='radiogroup'
            aria-label={"Scegli " + name}
            className="select-popup d-lg-none"
            animation={false}
            fullscreen={true} 
            backdrop={true}>
            <Container aria-hidden={true} fluid className="title d-flex justify-content-between align-items-center" >
                <div className="field-name">{showName}</div>
            </Container>
            <Container  
                className={"options row align-self-center pt-3 px-3" }>
                {choices.filter(x => x === "Non so").map(choice => <RenderOptionButtonComponent   name={showName}
                    cell={cell}
                    action={choice}
                    key={choice}
                />)}
                {choices.find(x => x === "Non so") && <div style={{ marginTop: "1rem", marginBottom: "1.5rem"}} aria-hidden="true"><DsyDivider /></div>}
                {choices.filter(x => x !== "Non so").map(choice => <RenderOptionButtonComponent
                    name={showName}
                    cell={cell}
                    action={choice}
                    key={choice}
                />)}
            </Container>
            <div className="confirm d-flex justify-content-center align-items-center px-3">
                <GriButton
                    role="button"  
                    label="Conferma scelta"
                    disabled={currentValue === undefined || currentValue === ""}
                    onClick={close}
                >Conferma</GriButton>
            </div>
            <button style={{position: "absolute", top: "8px", height: "48px", width: "48px", right: "16px", zIndex: 1000002}} 
                className="close-button accent no-border"  
                onClick={() => { dispatch(set({cell: cell, value: undefined})); close()}}>
              <span className="d-none d-lg-block">  { closeCopy }</span>
            <img height={14} src={closeIcon} alt={"Chiudi scelta " + name }/></button>
            </Modal>
    );
}

export default SelectPopupButtonsComponent;
