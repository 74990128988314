import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../../../reducers/data'
import React, { useState } from 'react'
import plug from "../../../icons/icon/plug.svg"


function GasDisclaimerComponent ({ name, cell, canBeNull, after, subtitle }) {
  const dispatch = useDispatch()
  const types = [ "Metano", "GPL", "Gasolio", "Aria Propanata"]
  const data = useSelector(get)
    if (types.includes(data["C25"]) || types.includes(data["C26"]) || types.includes(data["C27"]))
        return (<div className="light-disclaimer mb-4">
            <h2 className="light-disclaimer-title">
                <img src={plug} role="presentation" alt="" />
                Passa all'elettrico!</h2>
            <p className="light-disclaimer-text f14">
                Scegli una fonte pulita e innovativa per la tua casa, proteggendoti così dall’<b>aumento del prezzo del gas</b>. 
                Questo aumento è legato alla <b>direttiva europea ETS2 (Emission Trading System)</b>, 
                che introduce <b>costi per le emissioni di CO<sub>2</sub>. L’ETS2 prevede che in futuro il consumatore del combustibile paghi per la relativa quota di CO<sub>2</sub> emessa.</b> 
                L'obiettivo è di contribuire alla <b>riduzione delle emissioni nette di gas a effetto serra di almeno il 55% entro il 2030</b> 
                 e al conseguimento della neutralità climatica entro il 2050. 
                Le nuove regole dell'ETS2 saranno implementate gradualmente, ma <b>diventerà operativo nel 2027</b> con una stima attuale di 45 €/t di CO<sub>2</sub> .Il gas consumato in un anno da una famiglia media emette circa 2 t di CO<sub>2</sub>. Non perdere l'occasione di investire in un futuro energetico a basse emissioni e più conveniente!
            </p>
        </div>)
  else return <></>

}

export default GasDisclaimerComponent