import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyCardWithTitle;

export const DsyCardWithTitle = createComponent(
  React,
  'dsy-card-with-title',
  LitComp,
  {},
);

export default DsyCardWithTitle;
