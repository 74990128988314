import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyLoader;

export const DsyLoader = createComponent(
  React,
  'dsy-loader',
  LitComp,
  {},
);
