import React, {useState} from 'react';
import {
    set, get
} from '../../../reducers/data';
import { useSelector } from 'react-redux';

function LabelComponent({ name, extraClass,cell, component, description }) {
    const data = useSelector(get);

    return (<div className={"label-component " + (extraClass ? extraClass : "") }>
        {component ? 
        <h2 className="title" dangerouslySetInnerHTML={{ __html:
          cell ? name.replaceAll("{cell}", data[cell]) : name }}>
        </h2>:<div className="title" dangerouslySetInnerHTML={{ __html:
          cell ? name.replaceAll("{cell}", data[cell]) : name }}>
        </div>}
        {description && <p className="subtitle">{description}</p>}

       </div>)
}

export default LabelComponent;