import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyButton;

export const DsyButtonV2 = createComponent(
  React,
  'dsy-button',
  LitComp,
  {},
);

export default DsyButtonV2;
