import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyInputMulti;

export const DsyInputMulti = createComponent(
  React,
  'dsy-input-multi',
  LitComp,
  {},
);

export default DsyInputMulti;
