import { configureStore } from '@reduxjs/toolkit';
import dataReducer from '../reducers/data.js';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// const store = configureStore({
//   reducer: {
//     data: dataReducer,
//   },
// });

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, dataReducer)
export const store = configureStore({
    reducer: {
      data: dataReducer,
    },
  });
  
export const storePersisted = configureStore({
  reducer: { data: persistedReducer },
  // devTools: process.env.NODE_ENV !== 'production',
  // middleware: [thunk]
})

export const persistor = persistStore(store)
// export const store;

