import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyInput;

export const DsyInputV2 = createComponent(
  React,
  'dsy-input',
  LitComp,
  {},
);

export default DsyInputV2;
