import React from 'react';
import FinalHeaderComponent from './FinalHeaderComponent';
import BeneficiFinaliComponent from './BeneficiFinaliDesktopComponent';
import ReviewOrDownload from './ReviewOrDownloadComponent';
import BookAppointment from './BookAppointmentComponent';
import ContactsModalComponent from './ContactsModalComponent';
import { Row, Col, Container} from  "react-bootstrap";
import logo from '../../../icons/storepos.svg'
import BannerImageComponent from '../banneImage/BannerImageComponent';
import Header from '../layout/Header';

function RisparmioFinaleComponent({main}) {
const [showModal, setShowModal] = React.useState(false);
const [showAppointment, setShowAppointment] = React.useState(false);
return (<div className="final d-lg-flex px-0 mx-0">
     <Header  className={"col-12 col-lg-5 col-xxl-4"} 
                main={main}
                title={"Scopri cosa abbiamo in serbo per te!"} 
                 />
        {/* <div className="d-none d-lg-flex col-lg-1 "></div> */}
        <main ref={main} className='form-slide d-flex col-lg-7 col-xxl-8 flex-column align-items-space-between' id="main">
        <div className="final-body">
                <h2 id="title" className='gen-title'> La soluzione energetica per la tua casa</h2>
                <p className="gen-subtitle">Ecco tutti i prodotti che abbiamo scelto per te: meno energia consumata, più risparmio in bolletta.</p>
                <FinalHeaderComponent />
                <BeneficiFinaliComponent />
                <ReviewOrDownload changeModal={(val)=> setShowModal(val)} />
                <ContactsModalComponent appointment={true} show={showAppointment} close={() => setShowAppointment(false)} />
                <ContactsModalComponent appointment={false} show={showModal} close={() => setShowModal(false)} />
                <div className="mb-5 mx-3 final-disclaimer">
                <p className="small-text">* Se sei già cliente Enel o decidi di diventarlo, hai accesso a un prezzo speciale grazie al finanziamento a tasso zero: TAN fisso 0,00% e TAEG 0,00%. Inoltre, per te c'è un bonus di 10€ al mese in bolletta per 36 mesi! E non preoccuparti, la consegna al piano è inclusa nel prezzo. 
                Se non sei cliente Enel, il tasso di finanziamento potrebbe variare. Se sei interessato ai nostri prodotti, parlane con il nostro consulente nel punto vendita. Sarà felice di darti tutte le informazioni sul finanziamento per aiutarti a capire il costo finale del prodotto che desideri. Ricorda di chiedere anche il documento “Informazioni europee di base sul credito ai consumatori” (SECCI) e una copia del contratto. In questo modo, avrai tutto il necessario per prendere una decisione informata sui costi e sulle condizioni.</p>

                <p className="small-text">** I risultati del test si basano sul confronto tra la temperatura come da impostazioni di fabbrica con modalità AI Energy e senza modalità AI Energy. I risultati possono variare a seconda delle condizioni e delle modalità di utilizzo.</p>

                <p className="small-text mb-5">***  Il consumo energetico di questo modello di [frigorifero con congelatore RB(L)38C***W**] è di 87kw/h/anno, con un'efficienza energetica superiore del 20% rispetto alla soglia minima della classe di efficienza energetica A (108,40 kWh/anno) per questo prodotto.</p>
                </div>
            </div>
            <BookAppointment changeModal={(val) => setShowAppointment(val)} />
        </main>
    {/* <div className="d-flex w-100">
        <Col sm={12} md={4} id="left-banner" as="header" className="d-flex col-12" >
            <div id="tondo" className="d-none d-lg-block" style={{zIndex: 0}}></div>
            <div className="sticky logo d-flex flex-column">
                <img src={logo} alt="vai alla home page di Enel X store" />
                <h1>Scopri cosa abbiamo in serbo per te!</h1>
                <BannerImageComponent extraClass="d-none d-lg-block" />
            </div>
        </Col>
        <Col sm={12} md={8} id="right-banner" className="col-12" as="main" ref={main}>
            <div style={{ maxWidth: "884px", margin: "0 auto", paddingLeft: "3rem", paddingRight: "3rem"}}>
                <h2 id="title">La soluzione energetica per la tua casa</h2>
                <p className="gen-subtitle">Ecco tutti i prodotti che abbiamo scelto per te: meno energia consumata, più risparmio in bolletta.</p>
                <FinalHeaderComponent />
                <BeneficiFinaliComponent />
                <ReviewOrDownload changeModal={(val)=> setShowModal(val)} />
                <ContactsModalComponent appointment={true} show={showAppointment} close={() => setShowAppointment(false)} />
                <ContactsModalComponent appointment={false} show={showModal} close={() => setShowModal(false)} />
            </div>
            <BookAppointment changeModal={(val) => setShowAppointment(val)} />
        </Col>
        </div> */}
    </div>
)
}

export default RisparmioFinaleComponent

