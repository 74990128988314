import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyTag;

export const DsyTag = createComponent(
  React,
  'dsy-tag',
  LitComp,
  {},
);

export default DsyTag;
