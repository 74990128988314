import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsySidebar;

export const DsySidebar = createComponent(
    React,
    'dsy-sidebar',
    LitComp,
    {}
);
