import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyContentSwitcher;

export const DsyContentSwitcher = createComponent(
    React,
    "dsy-content-switcher",
    LitComp,
    {}
)