import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyDropdownButton;

export const DsyDropDownButton = createComponent(
  React,
  'dsy-dropdown-button',
  LitComp,
  {},
);
