import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyTextarea;

export const DsyTextarea = createComponent(
  React,
  'dsy-textarea',
  LitComp,
  {},
);

export default DsyTextarea;
