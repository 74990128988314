
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React from 'react';
import infoIcon from "../../../icons/icon/open-link.svg"
import { GriCheckboxComponent } from '../../dsy-inhouse/GriCheckboxComponent';


function AcceptComponent({ name, cell, extraClass, ariaLabel, description, href, disableIf, component, externalLink, children }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const disabled = disableIf && (data[disableIf.cell] == disableIf.value || data[disableIf.cell] == undefined)
    return (<>
    
        <div className={"switch-component accept-final d-flex flex-row align-items-center justify-content-between " + (data ? "filled " : "") + (extraClass ?? "") + (description === undefined ? " mb-16 " : "") }>
            <label className="d-flex align-items-center py-3"  htmlFor={"id-" + cell}>
            <GriCheckboxComponent
                id={"id-" + cell}
                checked={currentValue === true}
                onChange={(e) => dispatch(set({cell: cell,value: !currentValue}))}
                ariaLabel={name} />
            {/* <DsyCheckbox 
                     dsyValue={currentValue === true} size="small" id={"id-" + cell}
                     tabIndex={0}
                     aria-label={"Checkbox per " + name}
                     role="checkbox"
                     positionText="right"
                     onKeyDown={(e) => { 
                        if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) {
                            dispatch(set({cell: cell,value: !currentValue}))
                      }
                    }}
                     handleChange={(e) => dispatch(set({cell: cell,
                                                   value: !currentValue}))} > 
                </DsyCheckbox> */}
                {/* <div style={{ marginRight: 16, borderRight: "1px solid #C2CDDD", paddingRight: 16, width:0, height:32}}></div> */}
                <div 
                    aria-hidden={true} 
                    style={{color: "#313B49", fontSize: "1rem", lineHeight: "1rem", paddingLeft: "1rem", marginLeft: "6px", paddingRight: "0.25rem", borderLeft:"1px solid #C2CDDD"}} 
                    className={"switch-description bold"}> {name} 
                    <p className="f14 mb-0 mt-2 text-secondary fw400" >{children}</p>
                    </div>
            </label>
            <a href={href} target="_blank" rel="noreferrer" aria-label={ariaLabel} >    
                <img src={infoIcon} alt="" className="me-3" />
            </a>
        </div>
        </>
    );
}

export default AcceptComponent;