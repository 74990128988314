import React from "react"
import Switch from "react-switch";
import Toggle from "react-toggle"
import { useRef } from "react"

export function GriToggleComponent({ label, onChange, checked, disabled, 
    ariaChecked, ariaHidden, ariaLabel, name, id}) {
   
    const toggle = useRef(null)

    const defaultStyle = {
        draggable:false,
        offColor:"#667790",
        onColor:"#d3135a",
        offHandleColor:"#FFF",
        onHandleColor:"#FFF",
        checkedIcon: <></>,
        uncheckedIcon: <></>,
        checkedHandleIcon: <></>,
        uncheckedHandleIcon: <></>,
        height: 24,
        width: 40,
    }

    return <Toggle 
        id={id ?? ""}
        icons={false}
        onChange={onChange}
        checked={checked}
        aria-label={ariaLabel ?? name}
        onKeyDown={(e) => { if (e.key === "Enter") onChange(e)}}
        />

    const sw = (<Switch
        ref={toggle}
        {...defaultStyle}
        id={id ?? ""}
        aria-label={ariaLabel ?? name} 
        onKeyDown={(e) => { if (e.key === "Enter") onChange(e)}}
        onChange={onChange} 
        checked={checked} 
        disabled={disabled} />)

    if (label === undefined)
        return sw
        

    return (<label className="gri-switch-component">
            <span className={"me-2" + (checked ? " bold" : "") }>{label}</span>
            <Switch
            id={id ?? ""}
            {...defaultStyle}
             onChange={onChange} checked={checked} disabled={disabled} />
        </label>)
  } 

                  