import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyTable;

export const DsyTable = createComponent(
    React,
    "dsy-table",
    LitComp,
    {}
)