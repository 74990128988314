import React, { useState, useEffect, useRef } from 'react';
import { Navigate,useLocation} from 'react-router-dom';
import ria from '../icons/ria.png';
import Header from './form/layout/Header';
import InfoButton from './form/info-button/InfoButtonComponent';
import { DsyDivider } from '../dsy-wrappers';
import money from "../icons/icon/new/money.svg"
import receipt from "../icons/icon/new/receipt.svg"
import report from "../icons/icon/new/report.svg"
import { GriButton } from './dsy-inhouse/GriButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../reducers/data';

function LandingRow ({ title, image, children} ){
    return (<>
    <div className="d-none d-lg-flex">
        <div className="landing-row-component d-flex justify-content-center align-items-center">
            <img className="landing-row-image" src={image} alt="" role="presentation" />
        <div className="landing-row">
            <h2 className="landing-row-title">{title}</h2>
            <p className="landing-row-content">
                {children}
            </p>
        </div>
        </div>
        <div className="my-4" aria-hidden="true"><DsyDivider /></div>
        </div>
        <div className="landing-row-component d-flex d-lg-none flex-column justify-content-center align-items-center">
            <div className="d-flex">
                <img className="landing-row-image" src={image} alt="" role="presentation" />
                <div className="landing-row-title">{title}</div>
            </div>
        <div className="landing-row">
            <div className="landing-row-content">
                {children}
            </div>
        </div>
        </div>
        <div className="my-4" aria-hidden="true"><DsyDivider /></div>
        </>
    )
}

function Landing({ negozi}) {
    const [shouldRedirect, setShouldRedirect] = useState(false)
    const main = useRef(null)
    const data = useSelector(get);
    const dispatch = useDispatch();

    useEffect(()=> {
        const params = new URLSearchParams(document.location.search);
        const userId = params.get('userid');
        if(userId && !data["UserID"]) {
            dispatch(set({ cell: "UserID", value: userId }))
        }
        console.log(userId);
        window.enel_data = {
            page: {
                pageInfo: {
                    pageName: "Simulatore - Landing Page",
                    pageURL: "https://simulatore-risparmio-energia.enelxstore.com"
                }
            }
        };
        window?._satellite?.track("pageview")
        if (negozi && !data[negozi])
            dispatch(set({cell: "negozi", value: true}))
    }, [])
    if (shouldRedirect)
        return <Navigate to="/form" />
  
    return (   <><div id="landing" className="d-lg-flex align-items-center justify-content-center">
        <Header 
                main={main}
                className={"col-lg-5 col-xxl-4"} 
                title={"Scopri in pochi minuti come migliorare l'efficienza energetica della tua casa e risparmiare sui consumi!"} 
                imageId={"landing"}
                 />
            <div className="d-none d-lg-block col-lg-0 col-xxl-2 blue-bg"></div>
            <main className="landing-box col-lg-7 col-xxl-5 " ref={main} >
            <div className="landing-text">
                <LandingRow title="Cosa tenere a portata di mano per la tua simulazione" image={receipt}>Per calcolare quanto potresti <b>ridurre i tuoi consumi</b>, avremo bisogno di alcune informazioni sulla tua casa e sulle <b>spese attuali attuali di luce e gas</b>. Ti consigliamo di tenere <b>l'ultima bolletta</b> a portata di mano. </LandingRow>
                <LandingRow title="Aiutaci ad elaborare la tua simulazione" image={report}><b>Rispondi a quante più domande possibile.</b> Troverai alcuni dati già <b>precompilati</b> per rendere il tutto più veloce, ma <b>se necessario puoi modificarli.</b></LandingRow>
                <LandingRow title="Cosa otterrai con questa simulazione?" image={money}><b>Riceverai una stima</b> del possibile <b>risparmio sui tuoi consumi</b>, calcolata attraverso un modello validato. Ti forniremo <b>consigli</b> su come ottimizzare la tua casa per <b>ridurre il consumo di energia</b> e <b>abbassarela bolletta.</b>
                    
                {/* <div className='d-flex align-items-center mt-3'>
                    <img className="" src={ria} id="ria" alt="Organismo di certificazione RINA"/>
                    <InfoButton 
                        title={"Cos'è RINA?"}
                        altTitle={"Apri una finestra con più informazioni sull'organismo di certificazione RINA"}
                        subtitle={'RINA è un organismo di certificazione di terza parte che fornisce una garanzia di indipendenza e trasparenza. Per maggiori informazioni visita il sito:  <br /> <br class="d-none d-lg-block" /> <a aria-label="apri il link sulla certificazione rina in una nuova finestra" href="https://www.rina.org/it/business/certification" target="_blank">www.rina.org/it/business/certification</a>'} />
                </div>            */}
                     </LandingRow>

                <p className="smaller-text" style={{ color: "#272C34"}}>
                    I tuoi dati verranno utilizzati solo ai fini della simulazione e possono anche essere indicativi. Tuttavia per ottenere una simulazione più vicina possibile alla realtà ti raccomandiamo di inserire informazioni quanto più precise possibili. 
                </p>

            {!data["negozi"] && <div className="mb-3">            
                <a href={"/dichiarazione.pdf" } target="_blank" rel="noreferrer" className="smaller-text " style={{ color: "#d3135a", textDecoration: "underline"}}>
                    Dichiarazione di accessibilità
                </a>
            </div>}

             <GriButton
             label="iniziamo"
             shape="square" 
             variant="primary"
             fullWidth={true} 
             onClick={() => setShouldRedirect(true)}  >Iniziamo!</GriButton>
        </div>
        </main>
        <div className="d-none d-lg-block col-lg-1 col-xxl-1 blue-bg"></div>
        <div id="tondo-full" className="d-none d-lg-block"></div>
    </div> 
    
    <div id="cookie-bar-enel">
    <div id="consent_blackbar"></div>

    </div>
 
    <div id="teconsent" ></div>
    </>);
}

export default Landing;