import { Col, Row, Table } from "react-bootstrap"
import { useSelector } from 'react-redux';
import {
    get
} from '../../../reducers/data.js';

import logo from "../../../icons/storepos.svg"
import checkmark from "../../../icons/icon/checkmark.svg"
import BannerImageComponent from "../banneImage/BannerImageComponent.jsx";


const TEST_MODE = false

function DesktopStep ({number, copy, icon, active, past}){
    let ariaLabel = `Step numero ${number} ${copy}`
    if (active) ariaLabel += " in corso" 
    if (past) ariaLabel += " passato"
    return (
        <><div role="listitem"
            aria-hidden={false}
            className={ "desktop-step " + (active ? "active" : "") + 
                    (number === 6 ? " last" : "") + (past ? " past" : "")}>
            <li className="visually-hidden">{ariaLabel}</li>
            <div className="step-box" aria-hidden={true}>
                <div className={"step-number-box " + (past ? "past" : "") }>
                    <div className="step-number">
                        {icon ? <img src={icon} alt=""/> : 
                        <span  aria-hidden={true} >{number}</span>}
                    </div>
                </div>
            </div>
            <div aria-hidden={true} className="step-copy">{active ? copy: ""}</div>
        </div>
    </> 
    )
}

function Header({ title, subtitle, page, className,hideSubtitleMobile, anchor, main, imageId }) {
    const showStepper = page !== undefined
    const extraClass = showStepper ? " extra-margin" : ""
    return (<><header id="form-header" className={className +extraClass} >
         {imageId === "landing" ?<></> :<div id="tondo" className="d-none d-lg-block"></div> } 
        
        <div className="title-box">
            <div>
                <a href="https://enel.it" id="enel-logo" target="_blank" rel="noreferrer" >
                    <img src={logo} className="logo" alt="Vai alla homepage di Enel Energia" />
                </a>
                </div>
            {showStepper &&<div className="progress-box d-lg-none">
                <div className="my-3 d-flex">
                    <div className={"progress-block " + (page >= 0 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 1 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 2 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 3 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 4 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 5 ? "active" : "")} ></div>
                </div>
                <Row>
                    <Col xs={6} className="step">{page + 1} di 6</Col>
                </Row>
        </div>}

            <div className="title">
                <h2 className="d-none d-lg-block " level="h1" level-size="3xl" >{title}</h2>
                <div className="d-lg-none d-flex">
                    <div className="title-text">{title}</div>
                        <BannerImageComponent pageTitle={title} extraClass="header-image" imageId={imageId} /> 
                </div>
            </div>
            {page !== undefined && 
                <div className="progress-desktop d-none d-lg-flex flex-row mt-3" role="list">
                    <DesktopStep past={page > 0} active={page === 0} number={1} copy="Dati abitazione" icon={page > 0 ? checkmark : undefined} />
                    <DesktopStep past={page > 1} active={page === 1} number={2} copy="Fonti energetiche" icon={page > 1 ? checkmark : undefined} />
                    <DesktopStep past={page > 2} active={page === 2} number={3} copy="Consumi attuali" icon={page > 2 ? checkmark : undefined} />
                    <DesktopStep past={page > 3} active={page === 3} number={4} copy="Report informativo" icon={page > 3 ? checkmark : undefined} />
                    <DesktopStep past={page > 4} active={page === 4} number={5} copy="Selezione nuove soluzioni" icon={page > 4 ? checkmark : undefined} />
                    <DesktopStep past={page > 5} active={page === 5} number={6} copy="Selezione opzioni energia" icon={page > 5 ? checkmark : undefined} />
            </div>}
        </div>
        <BannerImageComponent pageTitle={title} extraClass="d-none d-lg-block" imageId={imageId} />
    </header>
     </>);
}

export default Header;