import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyModal;

export const DsyModal = createComponent(
  React,
  'dsy-modal',
  LitComp,
  {},
);

export default DsyModal;
