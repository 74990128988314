
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, {useState} from 'react';
import { current } from '@reduxjs/toolkit';
import { DsyInputWithMessage } from '../../../dsy-wrappers';
// import { parse } from 'dotenv';

function TextComponent({ name, cell, type, pattern, onBlur, isError }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    return (<div className={"contact-fields mb-16" + (isError ? " error" : "") } >
        <DsyInputWithMessage 
        // placeholder={name} 
        type="text"
        label={name}
        dsyValue={currentValue ?? ""}
        handleBlur={onBlur}
        handleChange={v => {
            if(cell === "Telefono")
                {
                    if (v.length > 0 && isNaN(v[v.length - 1]) && v !== "+")
                        return;

                    dispatch(set({
                            cell: cell,
                            value: v
                    }))
                }
                else
                    dispatch(set({
                        cell: cell,
                        value: v
                    }))
            if (onBlur)
                onBlur(v)
        }}
         >

        </DsyInputWithMessage>
        {/* <div className={"text-component d-flex flex-column input-border justify-content-center " + (data ? "filled" : "") }>
            {currentValue && <div className="fieldname">{name}</div>}
            <input
                className=''
                type={ type ?? "text" }
                placeholder={name}
                pattern={pattern || undefined}
                onBlur={onBlur ?? (() => {})}
                value={currentValue} onChange={(e) => {
                    if(cell === "Telefono")
                    {
                        const v = e.target.value;
                        if (v.length > 0 && isNaN(v[v.length - 1]) && v !== "+")
                            return;

                        dispatch(set({
                                cell: cell,
                                value: e.target.value
                        }))
                    }
                    else
                        dispatch(set({
                            cell: cell,
                            value: e.target.value
                        }))
                }}
            />
        </div> */}
        </div>
    );
}

export default TextComponent;