import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyTabs;

export const DsyTabs = createComponent(
  React,
  'dsy-tabs',
  LitComp,
  {},
);
