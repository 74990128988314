import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyInput;

export const DsyInput = createComponent(
    React,
    "dsy-input",
    LitComp,
    {}
)