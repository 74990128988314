import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import logo from "../icons/storepos.svg"
import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../reducers/data'
import {  DsyIcon } from '../dsy-wrappers';
import BannerImageComponent from './form/banneImage/BannerImageComponent';
import Lottie from "react-lottie-player"
import pigAnimation from "../animations/Fuochi-d'artificio.json"
import { GriButton } from './dsy-inhouse/GriButtonComponent';
import GriLottie from './dsy-inhouse/GriLottiePlayer';


function Thanks({ error, enelstore, retry, main }) {
    const dispatch = useDispatch()
    const data = useSelector(get)
    const messageRef = React.createRef();

    const [loading, setLoading] =useState(false)
    const [sendMessage, setSendMessage] = useState("")
    const sendProject = async () => {
        setLoading(true)
        await fetch("https://greenovationdashboard.azurewebsites.net/api/enelnegozi/?id=" + data["EnelCoreModelId"] + "&name=" + data["Nome"], {
            method: "POST"
        })
        setLoading(false)
        setSendMessage("Progetto inviato!")
    }

    const downloadProject = () => {
        document.location.href = data["ProjectUrl"]
    }

    useEffect(()=> {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = () => {
            window.history.go(1);
            window.location.href = "/form"
        };

        window.enel_data = {
            page: {
                pageInfo: {
                    pageName: "Simulatore - Thank you page",
                    pageURL: "https://simulatore-risparmio-energia.enelxstore.com/thanks"
                }
            }
        };
        window?._satellite?.track("pageview")
        messageRef.current.focus()
    }, [])

    if (data["negozi"]){
        return <div className="thanks d-flex flex-column">
        <h2 className="visually-hidden" aria-live="polite" aria-hidden="false" aria-relevant="all" role="banner" ref={messageRef} tabIndex={0}>
              {error ? 
              "Purtroppo al momento non abbiamo una soluzione adatta per le tue esigenze":
              "Il report è stato inviato alla casella email indicata"  }
          </h2>
          <div id="tondo-full" className="d-none d-lg-block"></div>
      <Container fluid>
          <Row>
              <div>
                  <img src={logo} role="presentation" alt="vai alla home page di Enel store" className='logo' />
              </div>
          </Row>
      </Container>
      <Container className="d-flex justify-content-center align-items-center flex-column">
          <Row>
              <Col lg={6}>
          <main ref={main}>
             
                  <h2>Evviva! Il report è stato inviato con successo!</h2>
                  <p>Se si decide di inviare il report finale al cliente, verrà recapitato direttamente nella sua <b>casella di posta elettronica.</b> <br />
                    Invieremo il risultato della simulazione alla email: 
      <br />
      <b>{window.localStorage["Email"]}</b> <br /> <br />
      
      <div style={{ width: "344px"}}>
          <GriButton 
          shape="square" 
          icon-library="material" 
          label="Scarica il tuo report"
          fullWidth={true}
          variant="secondary" 
          onClick={()=> { 
            sendProject()
          } } 
          icon-size="right" 
           >
              Invia report al cliente
          </GriButton>
          <div className="my-3" ></div>
          
          

           <GriButton 
           fullWidth={true}
          shape="square" 
          icon-library="material" 
          onClick={()=> { 
            sendProject()
            downloadProject()
            } } 
          variant="outline" 
          icon-size="right" 
           >
              Invia report al cliente e stampa
          </GriButton>
          {loading && <Spinner id="spinner" size={"bg"} />}
            <div className="mt-3">{sendMessage}</div>
          </div>
          
      </p>
          </main>
          </Col>
          <Col lg={6} >
          {/* <div className="d-flex justify-content-center align-items-center" style={{ height:"300px", position: "relative"}}>     
            
          <BannerImageComponent />
          </div> */}

          </Col>
          </Row>      
      </Container>
  </div>
    }

    return (<div className="thanks d-flex flex-column">
          <h2 className="visually-hidden" aria-live="polite" aria-hidden="false" aria-relevant="all" role="banner" ref={messageRef} tabIndex={0}>
                {error ? 
                "Purtroppo al momento non abbiamo una soluzione adatta per le tue esigenze":
                "Il report è stato inviato alla casella email indicata"  }
            </h2>
            <div id="tondo-full" className="d-none d-lg-block"></div>
        <Container fluid>
            <Row>
                <div>
                    <img src={logo} role="presentation" alt="vai alla home page di Enel store" className='logo' />
                </div>
            </Row>
        </Container>
        <Container className="d-flex justify-content-center align-items-center flex-column">
            <Row>
                <Col lg={6}>
            <main ref={main}>
                {error ? <>
                    <div className="d-flex mx-auto mb-3" style={{ maxWidth: 300, minWidth: 300}}>
                        {/* <Lottie loop={false} animationData={pigAnimation} height={300} width={300} />  */}
                    </div>                   {/* <img src={alert} alt="thanks" /> */}
                    <h2>Wow, hai raggiunto il massimo dell’efficienza energetica! 🌟</h2>
                    <p dangerouslySetInnerHTML={{  __html: error}}></p>
                    <div className="d-flex d-lg-block justify-content-center">
                    <GriButton shape="square" icon-library="material" variant="secondary" onClick={()=> { document.location.href = "/" } } icon-size="right" icon="home" label="Torna alla homepage" > Torna alla homepage</GriButton>
                    </div>
                </>
                :<>
                {data.Error && <p className="visually-hidden">{data.Error}</p>}
                  {/* <img src={data.Error ? alert : circle} alt="thanks" /> */}
                    <h2>{data.Error ? "Siamo spiacenti" : "Evviva! Il report è stato inviato con successo!"}</h2>
                    <p>{data.Error ? "La tua zona non è ancora coperta dal servizio." :<>Un nostro consulente tecnico ti contatterà al più presto. Riceverai una telefonata da uno dei <a aria-label="Apre una nuova scheda con l'elenco dei numeri telefonici utilizzati da ENEL per il ricontatto" style={{ fontWeight:"bold" }} href="https://www.enelxstore.com/it/it/support-homepage/how-to-buy/telefonate-commerciali/numeri-telefonici-utilizzati-da-enel-x-per-offerte-commerciali" target="_blank" rel="noreferrer" >seguenti numeri.</a>
        <br />
        Controlla la tua casella di posta elettronica, ti abbiamo inviato il risultato della simulazione alla email:  <br />
        <b>{window.localStorage["Email"]}</b> <br /> <br />
        Oppure clicca qui per scaricarlo <br /> <br />
        <a href={data["ProjectUrl"]} target="_blank" rel="noreferrer">
            <GriButton shape="square" 
            icon-library="material" 
            label="Scarica il tuo report"
            variant="secondary" 
            onClick={()=> { 
                document.location.href = data["ProjectUrl"]
            } } 
            icon-size="right" 
             >
                Scarica il tuo report
            </GriButton>
        </a>

        <p className='bold mt-4 mb-3'>Vuoi venire a trovarci per rivedere assieme il tuo report?</p>
        <p> <b>Trova il nostro negozio più vicino!</b> I nostri consulenti sono pronti a rivedere il tuo report e a rispondere a tutte le tue domande su come migliorare l'efficienza energetica della tua casa.</p>
        <a href="https://www.enel.it/spazio-enel?ecid=paidsearch-google-alwayson_b2c-brand-spazio_enel_bmm_e_exact_generico&gad_source=1&gclid=Cj0KCQjwsoe5BhDiARIsAOXVoUt3cuDeIfzspbaMa6NL_Y1bHdToaxuU02esLzwqjx6XKnOVub8bIYQaAnswEALw_wcB&gclsrc=aw.ds" target="_blank" rel="noreferrer">
            <GriButton 
            shape="square" 
            icon-library="material" 
            label="Apre una nuova finestra con l'elenco dei punti vendita"
            variant="outline" 
            icon-size="right" 
             >
                Visita il nostro punto vendita
            </GriButton>
        </a>
        <p className='bold mt-4 mb-3'>Visita il sito di Enel per conoscere tutte le soluzioni di risparmio energetico disponibili!</p>
        <a href="https://enel.it/" target="_blank" rel="noreferrer">
            <GriButton 
            shape="square" 
            icon-library="material" 
            label="Apre una nuova finestra su enel.it"
            variant="outline" 
            icon-size="right" 
             >
                Vai sul nostro sito
            </GriButton>
        </a>
        </> 
        
        }</p>
                </>}
                {retry === true &&  <a href="https://simulatore-risparmio-energia.enelxstore.com/"><button>CAMBIA COMUNE</button></a>}
            </main>
            </Col>
            <Col lg={6}>
            {error &&   <GriLottie animationData={pigAnimation} height={300} width={300} /> 
             }
            </Col>
            </Row>

            
        </Container>
    </div>);
}

export default Thanks;