import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyDetailsBoxGroup;

export const DsyDetailsBoxGroup = createComponent(
  React,
  'dsy-details-box-group',
  LitComp,
  {},
);
