import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyImage;

export const DsyImage = createComponent(
  React,
  'dsy-image',
  LitComp,
  {},
);

export default DsyImage;
