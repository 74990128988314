import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyIcon;

export const DsyIcon = createComponent(
    React,
    "dsy-icon",
    LitComp,
    {}
)