import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyInputSearch;

export const DsyInputSearch = createComponent(
  React,
  'dsy-input-search',
  LitComp,
  {},
);

export default DsyInputSearch;
