
import React from 'react';
import { DsyDivider } from '../../../dsy-wrappers';


function DisclaimerComponent({ title, subtitle }) {

    return (<div className="mt-3 disclaimer-component">
            <div aria-hidden="true"><DsyDivider ></DsyDivider> </div>
            <h2 className="disclaimer-title mt-3 mb-1">{title}</h2>
            <p className="disclaimer-subtitle">{subtitle}</p>
        </div> 
    );
}

export default DisclaimerComponent;