import { lampadine } from "./page2Images"

const marginX = 15
const marginY = 15
const lefGap = 0;

function bulbStatus (amount, step, index) {
    if (!amount || amount < 0) return "unknown";
    if (parseInt(amount / (step)) >= index) return "on";
    return "off";
}

export default function LampadineMatrix({amount, rows, cols, step}) {

    rows = !!rows ? rows : 5
    cols = !!cols ? cols : 6
    step = !!step ? step : 500

    const matrix = []

    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
            const style = {
                top: `${i * marginY}%`,
                left: `${(j * marginX) + lefGap}%`,
                width: "15%"
            }
            matrix.push(<img src={lampadine[bulbStatus(amount, step, (cols * i) + j + 1)]} style={style} alt="" role="presentation" />)
        }
    }

    return matrix

}