import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyList;

export const DsyList = createComponent(
  React,
  'dsy-list',
  LitComp,
  {},
);

export default DsyList;
