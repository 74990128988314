import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyDateTimePicker;

export const DsyDateTimePicker = createComponent(
  React,
  'dsy-date-time-picker',
  LitComp,
  {},
);

export default DsyDateTimePicker;
