import * as banners from "./page0Images"
import EnergyFlagComponent from './EnergyFlagComponent.jsx';
import PeopleComponent from "./PeopleComponent.jsx";

import Lottie from "react-lottie-player"
import zollaAnimation from "../../../animations/Zolla-+-Pin.json"
import GriLottie from "../../dsy-inhouse/GriLottiePlayer.jsx";

const page0styles = {
    monofamiliare: {
        __self__: {
            img: banners.monofamiliare,
            style: {
                left: "0",
                top: "0",
            }
        },
        zolla: {
            img: banners.zolla1,
            style: {
                top: "0",
                left: "0",
            }
            
        },
        ringhiera:{
            img: banners.ringhiera,
            style: {
                bottom: "62%",
                left: "40%",
                width: "18%"
            }
        },
        muratura:{
            img: banners.muratura,
            style: {
                bottom: "62%",
                left: "40%",
                width: "18%"
            }
        },
        fotovoltaico: {
            img: banners.fotovoltaico,
            style: {
                top: "7%",
                left: "12%",
                width: "17%"
            }
        },
        ristrutturato: {
            img: banners.ristrutturato,
            style: {
                top: "15%",
                left: "-5%",
                width: "10%"
            }
        },
        coibentato: {
            img: banners.coibentato,
            style: {
                top: "5%",
                left: "41%",
                width: "10%",
                transform: "rotate(5deg)"
            }
        },
        flag: {
            img: null,
            style: {
                top: "0%",
                right: "24%",
                width: "18%"
            }
        },
        airco: {
            img: banners.airco,
            style: {
                top: "46%",
                left: "12%",
                width: "14%"
            }
        },
        acHeat: {
            img: banners.acHeat,
            style: {
                top: "46%",
                left: "12%",
                width: "14%"
            }
        },
        people: {
            img: banners.people.right,
            style: {
                bottom: "34%",
                right: "28%",
                width: "32%"
            }
        }
    },
    bifamiliare: {
        __self__: {
            img: banners.bifamiliare,
            style: {
                left: "0",
                top: "0",
            }
        },
        zolla: {
            img: banners.zolla1,
            style: {
                top: "0",
                left: "0",
            }
        },
        ringhiera:{
            img: banners.ringhiera,
            style: {
                top: "16%",
                left: "59%",
                width: "18%"
            }
        },
        muratura:{
            img: banners.muratura,
            style: {
                top: "16%",
                left: "59%",
                width: "18%"
            }
        },
        fotovoltaico: {
            img: banners.fotovoltaico,
            style: {
                top: "0%",
                left: "24%",
                width: "14%"
            }
        },
        ristrutturato: {
            img: banners.ristrutturato,
            style: {
                top: "5%",
                left: "15%",
                width: "8%"
            }
        },
        coibentato: {
            img: banners.coibentato,
            style: {
                top: "8%",
                left: "53%",
                width: "8%",
                transform: "rotate(5deg)"
            }
        },
        flag: {
            img: null,
            style: {
                top: "22%",
                right: "11%",
                width: "18%"
            }
        },
        airco : {
            img: banners.aircoMirror,
            style: {
                bottom: "50%",
                right: "12%",
                width: "10%"
            }
        },
        acHeat : {
            img: banners.acHeatMirror,
            style: {
                bottom: "50%",
                right: "12%",
                width: "10%"
            }
        },
        people: {
            img: banners.people.left,
            style: {
                bottom: "37%",
                right: "38%",
                width: "32%"
            }
        }
    },
    piccolo: {
        __self__: {
            img: banners.piccolo,
            style: {
                left: "0",
                top: "0",
            }
        },
        zolla: {
            img: banners.zolla1,
            style: {
                top: "0",
                left: "0",
            }
        },
        ringhiera:{
            img: banners.ringhiera,
            style: {
                top: "34%",
                left: "59%",
                width: "18%"
            }
        },
        muratura:{
            img: banners.muratura,
            style: {
                top: "34%",
                left: "59%",
                width: "18%"
            }
        },
        fotovoltaico: {
            img: banners.fotovoltaico,
            style: {
                top: "16%",
                left: "30%",
                width: "14%"
            }
        },
        ristrutturato: {
            img: banners.ristrutturato,
            style: {
                top: "14%",
                left: "9%",
                width: "9%"
            }
        },
        coibentato: {
            img: banners.coibentato,
            style: {
                top: "8%",
                right: "45%",
                width: "10%",
                transform: "rotate(5deg)"
            }
        },
        flag: {
            img: null,
            style: {
                top: "14%",
                right: "23%",
                width: "18%"
            }
        },
        airco : {
            img: banners.aircoMirror,
            style: {
                bottom: "45%",
                left: "62%",
                width: "15%"
            }
        },
        acHeat : {
            img: banners.acHeatMirror,
            style: {
                bottom: "45%",
                left: "62%",
                width: "15%"
            }
        },
        people: {
            img: banners.people.group,
            style: {
                bottom: "40%",
                right: "54%",
                width: "32%"
            }
        }
    },
    medio: {
        __self__: {
            img: banners.medio,
            style: {
                left: "0",
                top: "0",
            }
        },
        zolla: {
            img: banners.zolla1,
            style: {
                top: "0",
                left: "0",
            }
        },
        ringhiera:{
            img: banners.ringhiera,
            style: {
                top: "32%",
                left: "60%",
                width: "18%"
            }
        },
        muratura:{
            img: banners.muratura,
            style: {
                top: "32%",
                left: "60%",
                width: "18%"
            }
        },
        fotovoltaico: {
            img: banners.fotovoltaico,
            style: {
                top: "2%",
                left: "19%",
                width: "14%"
            }
        },
        ristrutturato: {
            img: banners.ristrutturato,
            style: {
                top: "5%",
                left: "6%",
                width: "10%"
            }
        },
        coibentato: {
            img: banners.coibentato,
            style: {
                top: "6%",
                left: "58%",
                width: "10%",
                transform: "rotate(5deg)"
            }
        },
        flag: {
            img: null,
            style: {
                top: "7%",
                right: "16%",
                width: "18%"
            }
        },
        airco : {
            img: banners.aircoMirror,
            style: {
                bottom: "40%",
                left: "62%",
                width: "15%"
            }
        },
        acHeat : {
            img: banners.acHeatMirror,
            style: {
                bottom: "40%",
                left: "62%",
                width: "15%"
            }
        },
        people: {
            img: banners.people.group,
            style: {
                bottom: "38%",
                right: "49%",
                width: "32%"
            }
        }
    },     
    grande: {
        __self__: {
            img: banners.grande,
            style: {
                left: "0",
                top: "0",
            }
        },
        zolla: {
            img: banners.zolla1,
            style: {
                top: "0",
                left: "0",
            }
        },
        ringhiera:{
            img: banners.ringhiera,
            style: {
                top: "22%",
                left: "54%",
                width: "18%"
            }
        },
        muratura:{
            img: banners.muratura,
            style: {
                top: "22%",
                left: "54%",
                width: "18%"
            }
        },
        fotovoltaico: {
            img: banners.fotovoltaico,
            style: {
                top: "4%",
                left: "30%",
                width: "14%"
            }
        },
        ristrutturato: {
            img: banners.ristrutturato,
            style: {
                top: "5%",
                left: "16%",
                width: "10%"
            }
        },
        coibentato: {
            img: banners.coibentato,
            style: {
                top: "0%",
                left: "45%",
                width: "10%",
                transform: "rotate(5deg)"
            }
        },
        flag: {
            img: null,
            style: {
                top: "1%",
                right: "22%",
                width: "18%"
            }
        },
        airco : {
            img: banners.aircoMirror,
            style: {
                bottom: "42%",
                left: "58%",
                width: "11%"
            }
        },
        acHeat : {
            img: banners.acHeatMirror,
            style: {
                bottom: "42%",
                left: "58%",
                width: "11%"
            }
        },
        people: {
            img: banners.people.group,
            style: {
                bottom: "35%",
                right: "46%",
                width: "32%"
            }
        }
    }
};

export default function handlePage0(data) {
    
    const initial = banners.italy;

    const result = []

    const tipologia = data["C11"]?.split(" ")[0]?.split("/")[0]?.toLowerCase()

    // base image wiht italy map, comes out where no images is present
    if (result.length === 0 && !tipologia && data["focus"] !== "C11" && !data["C14"]) {
         return [<img src={initial} alt="" role="presentation"/>]
    }

    // condominio + zolla
    if (data["C11"]){
        const zolla = page0styles[tipologia].zolla
        const condo = page0styles[tipologia].__self__
        result.push(<img style={zolla.style} src={zolla.img} alt="" role="presentation" />)
        result.push(<img style={condo.style} src={condo.img} alt="" role="presentation" />)
    }
    else if (data["C14"]) {
        //TODO: put zolla image for focus
        //const condo = page0styles[tipologia].__self__
        result.push(<GriLottie animationData={zollaAnimation} />)      //  style={{ width: 300, height: 300 }}
         //result.push(<img style={condo.style} src={condo.img} alt="" role="presentation" />)
    }
    // balconi
    if (!!data["TipologiaBalconi"] && page0styles[tipologia] && data["PresenzaBalcone"] === true){
        const balconyType = data["TipologiaBalconi"].toLowerCase()
        const balcony = page0styles[tipologia][balconyType]
        result.push(<img style={balcony.style} src={balcony.img} role="presentation" alt="" />)
    }
    // antenna fotovoltaico
    if (!!data["PresenzaFotovoltaico"] && page0styles[tipologia]) {
        const fotovoltaico = page0styles[tipologia].fotovoltaico
        result.push(<img style={fotovoltaico.style} src={fotovoltaico.img} role="presentation" alt="" />)
    }
    // stelline ristrutturato
    if (!!data["RistrutturatoUltimi10Anni"] && page0styles[tipologia]) {
        const ristrutturato = page0styles[tipologia].ristrutturato
        result.push(<img style={ristrutturato.style} src={ristrutturato.img} role="presentation" alt="" />)
    }
    // cuffietta coibentato
    if (!!data["Coibentato"] && page0styles[tipologia]){
        const coibentato = page0styles[tipologia].coibentato
        result.push(<img style={coibentato.style} src={coibentato.img} role="presentation" alt="" />)
    }
    // bandierina classe energetica
    if (!!data["C36"] && page0styles[tipologia] ) {
        const flag = page0styles[tipologia]?.flag
        result.push(<EnergyFlagComponent style={flag.style} energyClass={data["C36"]}/>)
    }
    // box aria condizionata + pompa di calore
    if (!!data["C19"]) {
        const key = !!data["CondizionatoriRiscaldamento"] ? "acHeat" : "airco";
        // console.log(tipologia, key, page0styles[tipologia][key])
        const air = page0styles[tipologia][key];
        result.push(<img style={air.style} src={air.img} role="presentation" alt="" />)
    }
    //people
    if (!!data["C15"] && data["C15"] > 0 && tipologia && data["screen"] !== 1) {
        const people = page0styles[tipologia].people
        result.push(<PeopleComponent style={people.style} amount={data["C15"]} images={people.img}/>)
    }
    
    return result
}