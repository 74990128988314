import pig from '../../../icons/banner/report/pig.svg'

import sun_selected from '../../../icons/banner/report/boxes/selected/sun.svg'
import water_selected from '../../../icons/banner/report/boxes/selected/water.svg'
import fridge_selected from '../../../icons/banner/report/boxes/selected/fridge.svg'
import dry_selected from '../../../icons/banner/report/boxes/selected/dry.svg'
import wash_selected from '../../../icons/banner/report/boxes/selected/wash.svg'
import tv_selected from '../../../icons/banner/report/boxes/selected/tv.svg'
import cook_selected from '../../../icons/banner/report/boxes/selected/cook.svg'
import fire_selected from '../../../icons/banner/report/boxes/selected/fire.svg'

import sun_grey from '../../../icons/banner/report/boxes/grey/sun.svg'
import water_grey from '../../../icons/banner/report/boxes/grey/water.svg'
import fridge_grey from '../../../icons/banner/report/boxes/grey/fridge.svg'
import dry_grey from '../../../icons/banner/report/boxes/grey/dry.svg'
import wash_grey from '../../../icons/banner/report/boxes/grey/wash.svg'
import tv_grey from '../../../icons/banner/report/boxes/grey/tv.svg'
import cook_grey from '../../../icons/banner/report/boxes/grey/cook.svg'
import fire_grey from '../../../icons/banner/report/boxes/grey/fire.svg'

import sun_big from '../../../icons/banner/report/boxes/big/sun.svg'
import water_big from '../../../icons/banner/report/boxes/big/water.svg'
import fridge_big from '../../../icons/banner/report/boxes/big/fridge.svg'
import dry_big from '../../../icons/banner/report/boxes/big/dry.svg'
import wash_big from '../../../icons/banner/report/boxes/big/wash.svg'
import tv_big from '../../../icons/banner/report/boxes/big/tv.svg'
import cook_big from '../../../icons/banner/report/boxes/big/cook.svg'
import fire_big from '../../../icons/banner/report/boxes/big/fire.svg'

const boxes = {
    selected: {
        sun: sun_selected,
        water: water_selected,
        fridge: fridge_selected,
        dry: dry_selected,
        wash: wash_selected,
        tv: tv_selected,
        cook: cook_selected,
        fire: fire_selected
    },
    grey: {
        sun: sun_grey,
        water: water_grey,
        fridge: fridge_grey,
        dry: dry_grey,
        wash: wash_grey,
        tv: tv_grey,
        cook: cook_grey,
        fire: fire_grey
    },
    big: {
        sun: sun_big,
        water: water_big,
        fridge: fridge_big,
        dry: dry_big,
        wash: wash_big,
        tv: tv_big,
        cook: cook_big,
        fire: fire_big
    }
}

export {
    pig,
    boxes
}