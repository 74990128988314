
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, {useRef, useState } from 'react';
import InfoButtonComponent from '../info-button/InfoButtonComponent';
import { GriToggleComponent } from '../../dsy-inhouse/GriToggleComponent';
import { GriCheckboxComponent } from '../../dsy-inhouse/GriCheckboxComponent';

function SwitchComponent({ name, cell, extraClass, type, info, nonso, description, href, disableIf, component, announceMessage,announceWhen }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const [firstTime, setFirstTime] = useState(true)
    const disabled = disableIf && (data[disableIf.cell] == disableIf.value || data[disableIf.cell] == undefined)

    const createAnnouncement = (currentValue) => {
        let announcement = ""
        if (currentValue === announceWhen){
            if (announceWhen === false && firstTime){
            }
            else {
                announcement = announceMessage
            }
        }
        else if (announceWhen !== undefined) {
            announcement = announceWhen[currentValue]
        }
        return announcement
    }

    let announcement = createAnnouncement(currentValue)

    const onSwitch = (e) => {
        if (href || disabled) return
        setFirstTime(false)

        dispatch(set({
            cell: cell,
            value: !currentValue
        }))
        if (e?.preventDefault)
            e.preventDefault()
    }
 
    return (<>
    <div
        className={(disabled ? "disab " : "") + "switch-component d-flex flex-row align-items-center justify-content-between " + (data ? "filled " : "") + (extraClass ?? "")  }>
            <label htmlFor={"id"+cell} aria-hidden={true} className={"switch-text clickable " + (currentValue === true ? "bold": "") } 
                        onClick={onSwitch}>
                        {/* <a href={href} target="_blank" rel="noreferrer"> */}
                            {name} 
                        {/* </a> */}
            </label>
           {info && <div className="d-lg-none px-3 mx-3 br1" ><InfoButtonComponent {...info} /></div>}
            <div className="">
                <div id="liveRegion" 
                        aria-live="polite" 
                        aria-atomic="true" 
                        aria-hidden={announcement === undefined || announcement?.length === 0}
                        tabIndex={-1}
                        className="visually-hidden">
                        {disabled && " attivabile selezionando la casella precendente."}
                    {announcement}</div>     
                {type === "check" ? 
                <GriCheckboxComponent
                    checked={currentValue === true}
                    ariaChecked={currentValue === true}
                    onChange={(e) => dispatch(set({
                        cell: cell,
                        value: e
                   }))}
                    ariaLabel={type === "check" ? "Checkbox per " + name : "Toggle per  " + name + (currentValue ? " attivato" : " disattivato")}
                    />
                : 
                <>
                <GriToggleComponent 
                    id={"id"+cell}
                    name={name}
                    checked={currentValue && currentValue !== "Non so"}
                    onChange={onSwitch}
                    />
               
                </>}
                </div>
            {info && <InfoButtonComponent extraClass="d-none d-lg-flex" {...info} />}
           
        </div>
        {description && <div className="switch-description mb-16">
            {description}
            </div>}
        {nonso && <div
            role="switch"
            aria-checked={currentValue === "Non so"}
            aria-roledescription='checkbox' className="bool-nonso" >
                <GriCheckboxComponent
                    id={"id"+cell}
                    checked={currentValue === "Non so"}
                    onChange={(e) => dispatch(set({
                        cell: cell,
                        value: "Non so"
                    }))}
                    ariaLabel={"Checkbox per " + nonso}
                    />
            <label htmlFor={"id" + cell} onClick={() =>  dispatch(set({
                cell: cell,
                value: "Non so"
            }))}> Non so</label>
        </div>}
        </>
    );
}

export default SwitchComponent;