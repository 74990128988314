
import room_default from "../../../icons/banner/stanze/default.svg"
import room_pellet from "../../../icons/banner/stanze/pellet.svg"
import room_legna from "../../../icons/banner/stanze/legna.svg"

// riscaldamento (termosifone)
import aria from "../../../icons/banner/riscaldamento/aria.svg"
import gas from "../../../icons/banner/riscaldamento/gas.svg"
import gasolio from "../../../icons/banner/riscaldamento/gasolio.svg"
import legna from "../../../icons/banner/riscaldamento/legna.svg"
import metano from "../../../icons/banner/riscaldamento/metano.svg"
import pellet from "../../../icons/banner/riscaldamento/pellet.svg"
import tubo from "../../../icons/banner/riscaldamento/tubo-centralizzato.svg"

//acqua calda
import h2o_aria from "../../../icons/banner/acqua-calda/aria.svg"
import h2o_gas from "../../../icons/banner/acqua-calda/gas.svg"
import h2o_gasolio from "../../../icons/banner/acqua-calda/gasolio.svg"
import h2o_legna from "../../../icons/banner/acqua-calda/legna.svg"
import h2o_metano from "../../../icons/banner/acqua-calda/metano.svg"
import h2o_pellet from "../../../icons/banner/acqua-calda/pellet.svg"

// cottura
import cook_gas from "../../../icons/banner/cottura/piano-gas.svg"
import oven_gas from "../../../icons/banner/cottura/forno-gas.svg"
import oven_metano from "../../../icons/banner/cottura/forno-metano.svg"
import oven_elettico from "../../../icons/banner/cottura/forno-elettrico.svg"
import oven_air from "../../../icons/banner/cottura/forno-aria.svg"



// heat group
const heatType = {
    aria,
    gas,
    gasolio,
    legna,
    metano,
    pellet,
    tubo
}

// hot water group
const hotWater = {
    aria: h2o_aria,
    gas: h2o_gas,
    gasolio: h2o_gasolio,
    legna: h2o_legna,
    metano: h2o_metano,
    pellet: h2o_pellet,
}

// kitchen group
const kitchen = {
    cook: {
        gas: cook_gas
    },
    oven: {
        gas: oven_gas,
        metano: oven_metano,
        elettrico: oven_elettico,
        aria: oven_air
    }
}

// stanze group

const rooms = {
    default: room_default,
    pellet: room_pellet,
    legna: room_legna
}

export {
    rooms,
    heatType,
    hotWater,
    kitchen
}
