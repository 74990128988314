import CashPileComponent from "./CashPileComponent"
import LampadineMatrix from "./LampadineMatrix";

function EnergyPile ({energyTypeRaw, energyValue, unknown, style}) {
    const energyType = energyTypeRaw?.split(" ")[0]?.split("/")[0]?.toLowerCase()
    const value = unknown  ? null : (!!energyValue ? parseInt(energyValue) : 0)
    return <CashPileComponent energyType={energyType} amount={value} style={style}/>
}

export default function handlePage2(data) {

    if (data.screen === 1) {
        const commonPileStyle = {
            zIndex: 1,
            width: "30%",
        }
        const yMargin = 8
        const xMargin = 25
        
        const dataControl = [
            { key: "elettrico" , value: "C33"},
            { key: "C25" , value: "F25"},
            { key: "C26" , value: "F26"},
            { key: "C27" , value: "F27"},
        ]
        const result = []
        const energies = []
        const unknown = data["C31"].toLocaleLowerCase() === "no"

        for (let i = 0; i < dataControl.length; i++) {
            const control = dataControl[i]
            const energy = (data[control.key] || control.key)?.split(" ")[0]?.split("/")[0]?.toLowerCase()
            const value  = data[control.value]

            if (energies.indexOf(energy)>=0) {
                continue
            }
            energies.push(energy)
            const sI = energies.length - 1
            result.push(<EnergyPile energyTypeRaw={energy} energyValue={value} unknown={unknown} style={{...commonPileStyle, top: `${10 + (sI*yMargin)}%`, right: `${70 - (sI*xMargin)}%`}} />)
        }
        return result;
    }

    if (data.screen === 2) {
        const amount = !!data["C34"] && data["C34"].toLocaleLowerCase() === "no" ? null : parseInt(data["C35"]);
        return [
            <LampadineMatrix amount={amount}/>
        ]
    }

    return []
}