
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, { useState} from 'react';
import top from "../../../icons/top.svg"
import bottom from "../../../icons/bottom.svg"
import center from "../../../icons/center.svg"
import fotovoltaico from "../../../icons/icon/fotovoltaico.svg"
import batteria from "../../../icons/icon/batteria.svg"

import Frigo_75 from "../../../icons/icon/new/Frigo_75.png"
import Frigo_Bespoke from "../../../icons/icon/new/Frigo_Bespoke.png"
import Foto_Batteria from "../../../icons/icon/new/Foto_Batteria.png"
import Foto_Solare from "../../../icons/icon/new/Foto_Solare.png"
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';
import ProductModalComponent from '../reusable/ProductModal';

function SelectAtViewBoxComponent({ name, cell, description, choices, horizontal, extraClass }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const [show, setShow] = useState(null)
    const icons = [bottom, center, top, fotovoltaico, batteria]

    const images = {Frigo_75, Frigo_Bespoke, Foto_Batteria, Foto_Solare}
    const showRate = process.env.REACT_APP_RATE === "true"

    return (
        <div className={"selectatview-component boxatview mb-16 " + (data ? "filled " : "") + (extraClass ?? "")}>
            <h2 className="title">{name} 
                {description && <p className="description">{description}</p>}
            </h2>
            {choices && <div  className={"choice-box no-border  " + (horizontal ?? "")} role="group">
                {choices.map((x, i) =>
                    <button style={{ marginBottom: "16px"}} key={i}
                        role="radio"
                        aria-label={x.text}
                        aria-checked={data[cell] === x.value}
                        onClick={(e)  => {
                            dispatch(set({
                                cell: cell,
                                value: x.value
                            }))
                        }}
                        onKeyDown={(e) => { 
                            if ((e.key === "ArrowLeft" || e.key === "ArrowUp") && e.currentTarget.previousElementSibling !== null ) {
                                    e.currentTarget.previousElementSibling?.focus();
                                    e.preventDefault();
                            }
                            else if ((e.key === "ArrowRight" || e.key === "ArrowDown") && e.currentTarget.nextElementSibling !== null) {
                                    e.currentTarget.nextElementSibling?.focus();
                                    e.preventDefault();
                            }
                        }
                    }
                        className={"text-start " + (currentValue === x.value ? "selected": "")} >
                             <div className="box-component no-border">
                                <div className='d-flex align-items-center'>
                                    <input aria-label={x.text} 
                                    tabIndex={-1}
                                    type="radio" 
                                    checked={data[cell] === x.value} 
                                    className="choice me-3" 
                                    name={name} 
                                    value={x.text}
                                    id={x.text}
                                ></input>
                                {x.image &&<img src={images[x.image]} className="image-select" alt="" role='presentation' /> }
                                <div style={{flexGrow: 1}}>
                                        <div className="title">{x.text}</div>
                                        <div className="serie">{x.subtitle}</div>
                                        {x.tags &&<div className="tags">
                                            {x.tags.map((tag, i) => 
                                                <div key={i} className="tag">{tag}</div>
                                            )}
                                        </div>}
                                        </div>
                                        {showRate && <div style={{ whiteSpace: "nowrap"}} className="d-none d-lg-block text-end"> {x.topChildren} </div>}
                                </div>
                                <p className="description pb-0 mb-0 d-none d-lg-block" dangerouslySetInnerHTML={{ __html: x.description}}></p>
                              
                            </div>
                            {showRate && <div className="d-lg-none">{x.bottomChildren}</div>}
                            <GriButton className="d-lg-none w-100 bg-secondary br-0 mb-2" 
                                        onClick={() => setShow(i)} 
                                        variant="link"
                                        label={"Apri scheda dettagli prodotto"} >Dettagli prodotto</GriButton>
                    </button>
                )
                }
            </div>}
            <div id="liveRegion" 
                    aria-live="polite" 
                    aria-atomic="true" 
                    className="visually-hidden"></div>

            <ProductModalComponent 
                show={show !== null}
                chiudi={() => setShow(null)}
                extraTitle={choices[show]?.text}
                serie={choices[show]?.subtitle}
                tags={choices[show]?.tags}
                description={choices[show]?.description}
                image={images[choices[show]?.image]}
                footnote={choices[show]?.footnote}
            
            />
        </div>
    );
}

export default SelectAtViewBoxComponent;