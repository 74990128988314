import {heatType, hotWater, kitchen, rooms} from "./page1Images.js"
import {termico as termicoImg} from './page0Images'
import ContatoreComponent from "./ContatoreComponent.jsx";

const riscaldamento = {
    aria: heatType.aria,
    gpl: heatType.gas,
    gasolio: heatType.gasolio,
    legna: heatType.legna,
    metano: heatType.metano,
    pellet: heatType.pellet,
    style: {
        right: "32%",
        top: "37%",
        zIndex: "4",
        width: "3.8%"
    }
};

const centralizzato = {
    img: heatType.tubo,
    style: {
        right: "84%",
        top: "29%",
        zIndex: "5",
        width: "5%"
    }
}

const acqua = {
    aria: hotWater.aria,
    gpl: hotWater.gas,
    gasolio: hotWater.gasolio,
    legna: hotWater.legna,
    metano: hotWater.metano,
    pellet: hotWater.pellet,
    style: {
        left: "16%",
        top: "39%",
        zIndex: "4",
        width: "3.8%"
    }
};

const cucina = {
    aria: {
        cook: kitchen.cook.gas,
        oven: kitchen.oven.aria
    },
    gpl: {
        cook: kitchen.cook.gas,
        oven: kitchen.oven.gas
    },
    metano: {
        cook: kitchen.cook.gas,
        oven: kitchen.oven.metano
    },
    elettrico: {
        cook: null,
        oven: kitchen.oven.elettrico
    },
    style: {
        cook: {
            left: "30%",
            top: "35%",
            zIndex: "4",
            width: "11.5%"
        },
        oven: {
            left: "37.5%",
            top: "38.6%",
            zIndex: "4",
            width: "3.3%"
        }
    }
};

const stanza = {
    default: rooms.default,
    pellet: rooms.pellet,
    legna: rooms.legna
}

const termico = {
    img: termicoImg,
    style: {
        left: "0%",
        top: "19%",
        zIndex: "4",
        width: "18%"
    }
}

const contatore = {
    style: {
        zIndex: "4",
    }
}

export default function handlePage1(data) { 

    if (data.screen === 2) {
        const amount = data["C61"].toLowerCase() === "si" ? parseInt(data["D61"]) : 0
        return [<ContatoreComponent style={contatore.style} amount={amount}/>]
    }
    
    const result = []
    // riscaldamento centralizzato o no
    const isCentralizzato = !!data['C24'];
    // tipo di riscaldamento
    const heatType = data["C25"]?.split(" ")[0]?.split("/")[0]?.toLowerCase()
    // tipo di riscaldamento acqua
    const waterType = data["C26"]?.split(" ")[0]?.split("/")[0]?.toLowerCase()
    // tipo di energia della cucina
    const kitchenType = data["C27"]?.split(" ")[0]?.split("/")[0]?.toLowerCase()
    // solare / termico
    const isTermico = !!data['C29'];

    if (isCentralizzato) {
        // il tubo viene sfasato
        result.push(<img src={centralizzato.img} style={centralizzato.style} alt="" role="presentation" />)
    }

    if (!isCentralizzato && !!heatType && !!riscaldamento[heatType]) {
        result.push(<img src={riscaldamento[heatType]} style={riscaldamento.style} alt="" role="presentation" />)
    }

    if (!!waterType && !!acqua[waterType]) {
        result.push(<img src={acqua[waterType]} style={acqua.style} alt="" role="presentation" />)
    }

    if (!!kitchenType && !!cucina[kitchenType]) {
        if (!!cucina[kitchenType].cook) {
            result.push(<img src={cucina[kitchenType].cook} style={cucina.style.cook} alt="" role="presentation" />)
        }
        if (!!cucina[kitchenType].oven) {
            result.push(<img src={cucina[kitchenType].oven} style={cucina.style.oven} alt="" role="presentation" />)
        }
    }

    if (isTermico) {
        result.push(<img src={termico.img} style={termico.style} alt="" role="presentation" />)
    }

    const room_background = stanza[heatType] || stanza[waterType] || stanza.default;
    // base image of the room
    result.push(<img src={room_background} alt="" role="presentation" />)
    
    return result
}