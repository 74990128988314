import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyCard;

export const DsyCard = createComponent(
  React,
  'dsy-card',
  LitComp,
  {},
);

export default DsyCard;
