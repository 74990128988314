import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from "../icons/storepos.svg"
import { useRouteError } from 'react-router-dom';
import Lottie from "react-lottie-player"
import pigAnimation from "../animations/Maialino-ricco.json"
import GriLottie from './dsy-inhouse/GriLottiePlayer';


function ErrorPage() {
    let error = useRouteError();
    console.log(error)

    return (<div className="thanks d-flex flex-column">
            <div id="tondo-full" className="d-none d-lg-block"></div>
        <Container fluid>
            <Row>
                <div>
                    <img src={logo} alt="vai alla home page di Enel store" role="presentation" className='logo' />
                </div>
            </Row>
        </Container>
        <Container className="d-flex justify-content-center align-items-center flex-column">
            <Row>
                <Col lg={6}>
            <main >
                {error ?? "Qualcosa è andato storto! Ti preghiamo di riprovare più tardi."}        
            </main>
            </Col>
            <Col lg={6}>
            {error &&  <GriLottie animationData={pigAnimation} height={300} width={300} /> 
             }
                {/* <BannerImageComponent extraClass="header-image" /> */}
            </Col>
            </Row>            
        </Container>
    </div>);
}

export default ErrorPage;