import React from "react"
import { DsyIcon } from "../../dsy-wrappers"


export function GriButton({ label, variant, children, disabled, onClick, fullWidth, icon, id, className}) {
    let cName = "dsy-button " + (variant ?? "primary")
    if (fullWidth)
      cName += " w-100"
    if (className)
      cName += " " + className
    
    return (<button id={id ?? ""} className={cName} disabled={disabled} aria-label={label} onClick={onClick}
    onKeyDown={(e) => {if (e.key === "Enter" || e.key ==="Space") onClick()}}>
          { icon ? <span aria-hidden={true} className="d-flex justify-content-center align-items-center"><DsyIcon className="me-2" name={icon} ></DsyIcon>  {children}</span>
          : children}  
    </button>)
  } 

                    //   icon-side="left" 
                    // icon-name="thin-arrow-left-secondary"  