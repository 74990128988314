
import closeIcon from "../../../icons/icon/close.svg"
import closeIconDesktop from "../../../icons/icon/closesmall.svg"

function InfoPopupComponent({ title, subtitle, close, keyDown }) {
        return (<div role="dialog"
                aria-modal="true" 
                aria-label={title + " " + subtitle}>
                <div aria-hidden="true" className="arrow-up"></div>
                <div className="info-popup shad4">
                        <div className='content'>
                                <div className="title d-flex align-items-center justify-content-between" id="dialog-title">
                                        {title}
                                </div>
                                <p tabIndex={0} className="text" id="dialog-description" dangerouslySetInnerHTML={{ __html: subtitle }}></p>
                                <button onClick={close}
                                        role-descrition="Pulsante"
                                        aria-label="Chiudi la finestra informativa" 
                                        style={{ background: "unset", border: 0, position:"absolute", right: "8px", top: "8px", zIndex: 20 }}> 
                                        <img src={closeIcon} className="d-lg-none" role="presentation" alt="" />
                                        <img src={closeIconDesktop} className="d-none d-lg-inline" role="presentation" alt="" />
                                </button>
                                {/* <button onClick={close} className="big-accent">Conferma</button> */}
                        </div>
                </div>
        </div>)
}

export default InfoPopupComponent;