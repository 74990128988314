export function clamp (n, min, max) {
    return Math.min(Math.max(n, min), max);
}

export function addPercentageToStyle(style, key, additionalPercentage) {
    const newStyle = {...style};
    // Extract the numeric value from the percentage string
    let currentPercentage = parseFloat(style[key]);
    // Add the additional percentage
    let newPercentage = currentPercentage + additionalPercentage;
    // Convert it back to a percentage string and update the style object
    newStyle[key] = newPercentage + '%';
    return newStyle;
}

export function traslateStyle(style, additionalPercentage) {
    return addPercentageToStyle(addPercentageToStyle(style, 'top', additionalPercentage), 'right', additionalPercentage)
}