import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyInputPassword;

export const DsyInputPassword = createComponent(
  React,
  'dsy-input-password',
  LitComp,
  {},
);

export default DsyInputPassword;
