import React from 'react';
import Dsy from './dsyui';
import { createComponent } from '@lit-labs/react';

const LitComp = Dsy.components.DsyToggle;

export const DsyToggle = createComponent(
  React,
  'dsy-toggle',
  LitComp,
  {},
);
