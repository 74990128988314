

function RisparmioBox({
    tag,
    title,
    description,
    icon,
    image,
    attualeTitle,
    attualeDescription,
    attualeValue,
    futuraTitle,
    futuraDescription,
    futuraValue,
    }) {
  
    const onlyIntegerEuroFormat = (number) => { 
      if (Number.isNaN(number)) return number;
      return (Number.parseFloat(number)?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " €").replace("-", "");
    }
    return (
      <div className="risparmio-box pb-3 d-md-flex justify-content-md-center align-items-md-center ">
        <div className="d-flex col-12 col-md-6 col-lg-6 pe-md-4 top align-items-center">
          <div className="img-container">
            {icon && <img src={icon} role="presentation" alt="" />}
            {image && <img src={image} className="w-100 h-100 rounded" role="presentation" alt="" />}
          </div>
          <div className="d-flex flex-column justify-content-between h-100">
            {tag && <div className="tag">{tag}</div>}
            <h2 className="title mb-0">{title}</h2>
            <div className="description f12" >{description}</div>
          </div>
        </div>
        <div className="risparmio-details d-flex justify-content-between col-12 col-md-6 col-lg-6">
          <div className="actual-futura">
          {attualeTitle && (
            <div className="attuale">
              <div className="mb-2 bold f14">{attualeTitle}</div>
              <div className="d-flex justify-content-between">
                <div>
                  <div className="f12">{attualeDescription}</div>
                  <div className="nowrap b">{attualeValue}</div>
                </div>
                {/* <div>
                  <div>{futuraTitle}</div>
                  <div className="nowrap b">13€ + CCV 10€ / mese</div>
                </div> */}
              </div>
            </div>
          )}
          </div>
            <div className={"risparmio f14 " + (title === "Fotovoltaico" ? " no-border" : "") }>
              <div className="mb-2 bold">{futuraTitle}</div>
              <div className="f12">&nbsp;
                    {futuraDescription}
                </div>
              <div className="b nowrap">{futuraValue}</div>
            </div>
        </div>
      </div>
    );
  }

  export default RisparmioBox;