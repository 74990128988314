import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyColumn;

export const DsyColumnV2 = createComponent(
  React,
  'dsy-column',
  LitComp,
  {},
);

export default DsyColumnV2;
