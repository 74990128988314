
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React, { useState } from 'react';
import InfoButton from '../info-button/InfoButtonComponent';
import SliderWithThumbComponent from './SliderWithThumbComponent';
import {  DsyIcon } from '../../../dsy-wrappers';
import DisclaimerComponent from '../reusable/DisclaimerComponent';
import FacSimileCopyComponent from '../fac-simile/FacSimileCopy';
import { GriCheckboxComponent } from '../../dsy-inhouse/GriCheckboxComponent';

function SliderComponent({ name, cell,sr, min, max, step, info, markers, canBeNull, disableIf, placeholder, description, unit, cellSiNo, extraClass, defaultValue, forceValue, facsimile,forceDisabled, cellSiNoCopy }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const [error, setError] = useState(data[cell] >= min && data[cell] <= max ? undefined : "Inserire un valore compreso tra " + min + " e " + max)
    const currentValue = data[cell]
    const [message, setMessage] = React.useState(undefined)
    const disabled = (forceDisabled === true) ||( disableIf && (data[disableIf.cell] == disableIf.value || data[disableIf.cell] == undefined))
    const checkAndSet = (cell, value) =>{
        if (min && value < min) {
            setError("Inserire un valore compreso tra " + min + " e " + max)
        } else if (max && value > max) {
            setError("Inserire un valore compreso tra " + min + " e " + max)
        }
        else setError(undefined)
        dispatch(set({
            cell: cell,
            value: value
        }))
    }

    const nonLaConosco = () => {
        const goingTo = data[cellSiNo] === "Si" ? "No" : "Si" 
        dispatch(set({ cell: cellSiNo, value: goingTo }))
        if (goingTo === "Si")setMessage("Componente per " + name + " abilitato. Checkbox Non lo so ")
        else setMessage("Componente per " + name + " disabilitato. Checkbox Non lo so ")
    } 

    const moveArrow = (e) => {
        const range = e.target;
        const value = range.value;
        const min = range.min || 0;
        const max = range.max || 100;
        let newVal = Number(((value - min) * 100) / (max - min));
        if (newVal < 0) newVal = 0;
        if (newVal > 100) newVal = 100;
        const thumb = range.closest(".sliderwiththumb").querySelector(".thumb");
        const fill = range.closest(".sliderwiththumb").querySelector(".fill");
        fill.style.width = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        if (newVal < 3)
            thumb.style.left = `calc(${newVal}% + (${8  - newVal * 0.15}px))`;    
        else if (newVal > 94)
            thumb.style.left = `calc(${newVal}% + (${-17 - newVal * 0.15}px))`;
        else    
            thumb.style.left = `calc(${newVal}% + (${-1 - newVal * 0.15}px))`;
    }
    const nonconosco = {
        "D61":"Non lo so",
        "C35":"Non lo so"
    }

    const dis = (data[cellSiNo] === "No" || (disableIf && data[disableIf.cell] == disableIf.value)) ? true : false
   
    return (<>
        <div aria-disabled={dis} className={"slider-component mb-16 d-flex flex-column  " + (extraClass ? extraClass : "") +  (info ? "mw344" : "") }>
            <div className="d-flex align-items-center mb-16 justify-content-between "><h2 className={"pre mb-0 title "}>{name}</h2> {info  && <InfoButton {...info} />}</div>
            {description &&<div className='description'>{description}</div> }
            <div id="liveRegion" aria-live="polite" className="visually-hidden">{error}{message}</div>
            <div aria-hidden={disabled} className={"slider-container "+(data[cellSiNo] === "No" || disabled ? " disa" : "" ) }>
                <SliderWithThumbComponent 
                  forceValue={forceValue}
                  name={name}
                  cell={cell}
                  unit={unit}
                  min={min} 
                  max={max} 
                  step={step}
                  disabled={disabled}
                  error={error}
                  currentValue={currentValue}
                  moveArrow={moveArrow}   
                  setCurrentValue={(value) => checkAndSet(cell, value) 
                } />
            </div>
        </div>
         {error && <div className="error-message d-flex" aria-errormessage={error} ><DsyIcon className="me-2" size="24px" library="ionicons" name="warning-outline" style={{ display: "inline-block"}}></DsyIcon> {error}</div>}
        {facsimile && <FacSimileCopyComponent {...facsimile}  />}
      {cellSiNoCopy && <DisclaimerComponent {...cellSiNoCopy} /> }
        {cellSiNo && <div className="bool-nonso mb-16 d-flex align-items-center"   >
            <GriCheckboxComponent 
                checked={data[cellSiNo] !== "Si"}
                onChange={nonLaConosco}
                id={"id" + cell}
                ariaLabel={ sr ?? "Non la conosco"} />
            {/* <DsyCheckbox dsyValue={data[cellSiNo] !== "Si"}
                handleChange={nonLaConosco}
                role="checkbox"
                tabIndex={0}
                onKeyDown={(e) => { 
                    if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) {
                        nonLaConosco()
                  }
                }}
                id={"id" + cell}  
                aria-label={sr ?? "Non la conosco"}
            ></DsyCheckbox> */}
            <label htmlFor={"id" + cell} style={{ marginLeft: "8px", cursor: "pointer"}} 
              aria-hidden={true}
            > {nonconosco[cell] ?? "Non la conosco"} </label>
            </div>}
    </>
    );
}

export default SliderComponent;