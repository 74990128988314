import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyDatePicker;

export const DsyDatePicker = createComponent(
  React,
  'dsy-date-picker',
  LitComp,
  {},
);

export default DsyDatePicker;
