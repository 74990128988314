import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyStatusTag;

export const DsyStatusTag = createComponent(
  React,
  'dsy-status-tag',
  LitComp,
  {},
);

export default DsyStatusTag;
