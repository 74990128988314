import * as images from './page3Images.js'
import { addPercentageToStyle } from "./bannerUtils"

const lefGap=0
const topGap=0

function SolutionBox({name, selected, style}) {

    if (!images.boxes.selected[name] || !images.boxes.grey[name]) {
        return []
    }

    return [
        !!selected ?
            <img src={images.boxes.selected[name]} style={style} alt="" role="presentation" /> :
            <img src={images.boxes.grey[name]} style={style} alt="" role="presentation" />
    ]
}

export default function handlePage4(data) {

    const commonBoxStyle = {
        width: "30%"
    }

    const boxesMap = {
        fire: {
            key: "C41",
            style: {
                left: "0%",
                top: "0%"
            }
        },
        water: {
            key: "",
            style: {
                top: "0%",
                left: "35%"
            }
        },
        cook: {
            key: "C48",
            style: {
                left: "70%",
                top: "0%"
            }
        },
        fridge: {
            key: "Final_Frigorifero",
            style: {
                top: "25%",
                left: "0%"
            }
        },
        wash: {
            key: "Final_Lavatrice",
            style: {
                left: "35%",
                top: "25%"
            }
        },
        dry: {
            key: "Final_Asciugatrice",
            style: {
                left: "70%",
                top: "25%"
            }
        },
        tv: {
            key: "Final_TV",
            style: {
                top: "50%",
                left: "13%"
            }
        },
        sun: {
            key: "C53",
            style: {
                left: "58%",
                top: "50%"
            }
        },
    }

    return Object.keys(boxesMap)
        .map((name) => {
            const selected = !!data[boxesMap[name].key];
            const style = addPercentageToStyle(addPercentageToStyle({...commonBoxStyle, ...boxesMap[name].style}, 'left', lefGap), 'top', topGap)
            return <SolutionBox key={name} name={name} selected={selected} style={style}/>
        })
}