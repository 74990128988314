import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsyButton;

// export const DsyButton = createComponent({
//   tagName: 'dsy-button',
//   react: React,
//   elementClass: LitComp,
//   style:{ border: '1px solid red' }
// })
export const DsyButton = createComponent(
  React,
  'dsy-button',
  LitComp,
  {},
);

// function DsyButton({ label, variant, children, disabled}) {
//   let cName = "dsy-button " + variant ?? ""

//   return (<button className={cName} disabled={disabled} aria-label={label}>
//     {children}
//   </button>)
// }

export default DsyButton;