
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import FormEnel from './components/FormEnel';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
// import App from './App';
import Landing from './components/Landing';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/store';
import WaitAndThanks from './components/form/layout/WaitAndThanks';
import FormEnelDesktop from './components/FormEnelDesktop';
import Thanks from './components/Thanks';
import './App.scss';
import './Small.scss';
import './Tablet.scss';
import './Desktop.scss';
import "./GriComponents.scss"


import ErrorPage from './components/ErrorPage'; // Make sure to create this component

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorPage />
  },
  {
    path: "/negozi",
    element: <Landing negozi={true} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/form",
    element: <>
    {/* <FormEnel /> */}
    <FormEnelDesktop />
    </>,
    errorElement: <ErrorPage />
  },
  {
    path:"/thanks",
    element: <Thanks />,
    errorElement: <ErrorPage />
  },
  {
    path:"/test",
    element: <ErrorPage loading={false} />,
    errorElement: <ErrorPage />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
     <Provider store={store}>
       {/* <PersistGate loading={null} persistor={persistor}> */}
        <RouterProvider router={router} />
        {/* </PersistGate> */}
    </Provider>
  // </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
