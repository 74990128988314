import unknown from "../../../icons/banner/contatore/unknown-kw.svg"
import onekw from "../../../icons/banner/contatore/1kw.svg"
import twokw from "../../../icons/banner/contatore/2kw.svg"
import threkw from "../../../icons/banner/contatore/3kw.svg"
import fourkw from "../../../icons/banner/contatore/4kw.svg"
import fivekw from "../../../icons/banner/contatore/5kw.svg"
import sixkw from "../../../icons/banner/contatore/6kw.svg"
import sevenkw from "../../../icons/banner/contatore/7kw.svg"
import eightkw from "../../../icons/banner/contatore/8kw.svg"
import ninekw from "../../../icons/banner/contatore/9kw.svg"
import tenkw from "../../../icons/banner/contatore/10kw.svg"

import red_onekw from "../../../icons/banner/contatore/red/1kw.svg"
import red_twokw from "../../../icons/banner/contatore/red/2kw.svg"
import red_threkw from "../../../icons/banner/contatore/red/3kw.svg"
import red_fourkw from "../../../icons/banner/contatore/red/4kw.svg"
import red_fivekw from "../../../icons/banner/contatore/red/5kw.svg"
import red_sixkw from "../../../icons/banner/contatore/red/6kw.svg"
import red_sevenkw from "../../../icons/banner/contatore/red/7kw.svg"
import red_eightkw from "../../../icons/banner/contatore/red/8kw.svg"
import red_ninekw from "../../../icons/banner/contatore/red/9kw.svg"
import red_tenkw from "../../../icons/banner/contatore/red/10kw.svg"

const kw = {
    1: onekw,
    2: twokw,
    3: threkw,
    4: fourkw,
    5: fivekw,
    6: sixkw,
    7: sevenkw,
    8: eightkw,
    9: ninekw,
    10: tenkw,
}

const redkw = {
    1: red_onekw,
    2: red_twokw,
    3: red_threkw,
    4: red_fourkw,
    5: red_fivekw,
    6: red_sixkw,
    7: red_sevenkw,
    8: red_eightkw,
    9: red_ninekw,
    10: red_tenkw,
}

const minKw = 1
const maxKw = 10

export default function ContatoreComponent ({style, amount, red}) {

    amount = parseInt(amount);

    if (amount < minKw || amount > maxKw) {
        return <img className="contatore" src={unknown} style={style} role="presentation" alt="" />
    } else {
        const _kw = red ? redkw : kw
        return <img className="contatore" src={_kw[amount]} style={style} role="presentation" alt="" />
    }
}