import React, {useState} from "react"
import { Container, Modal, Accordion } from "react-bootstrap"
import closeIcon from "../../../icons/icon/close.svg"

  function FacSimileComponent ({ show, close, img, copy, n, facsimile, top, bottom }) {
    return (
      <Modal
        aria-modal="true"
        role="dialog"
        show={show}
        centered={true}
        className='select-popup modal-pictures'
        animation={false}
        fullscreen={true}
        backdrop={false}
        aria-label="Visualizza FAC-SIMILE bolletta"
      >
        <Container
          fluid
          className='title d-flex justify-content-between align-items-center'
        >
          <div className='field-name'>Dove si trova questa informazione?</div>
       
        </Container>
  
        <Container fluid className="p-3">
        <Accordion className="" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Hai una bolletta ENEL?</Accordion.Header>
            <Accordion.Body>
             {top}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Hai una bolletta di un altro fornitore?</Accordion.Header>
            <Accordion.Body>
             {bottom}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        </Container>
        <div style={{ position:"absolute", right:"16px", top: "16px", zIndex: 2147483647}} 
            role="button" 
            aria-label="Chiudi" 
            className='accent' 
            onKeyDown={(e) => { if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) close() }}
            onClick={close} 
            tabIndex={0}>
            <img height={14} src={closeIcon} alt='' role="presentation" />
          </div>
      </Modal>
    )
  }

  export default FacSimileComponent