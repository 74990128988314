import React from 'react';
import { createComponent } from '@lit-labs/react';

import Dsy from './dsyui';

const LitComp = Dsy.components.DsySlider;

export const DsySlider = createComponent(
  React,
  'dsy-slider',
  LitComp,
  {},
);
