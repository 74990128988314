import { createComponent } from '@lit-labs/react';
import React from 'react';
import Dsy from './dsyui';

const LitComp = Dsy.components.DsyLink;

export const DsyLink = createComponent(
  React,
  'dsy-link',
  LitComp,
  {},
);
