import React, {useState} from 'react';
import { Col } from 'react-bootstrap';
import TV_Pic from "../../../icons/icon/new/TV_Pic.png"

import FotoVirtuale from "../../../icons/icon/new/FotoVoltaicoVirtuale.png"
import Pompa from "../../../icons/icon/pompa.png"
import { useSelector } from 'react-redux';
import {
    get
} from '../../../reducers/data';
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';
import ProductModalComponent from './ProductModal';
import FotoRinghiera from "../../../icons/icon/new/FotoRinghiera.png"
import FotoMuratura from "../../../icons/icon/new/FotoMuratura.png"
import { DsyDivider } from '../../../dsy-wrappers';

const images = {
    TV_Pic,
    FotoVirtuale,
    Pompa,
    FotoRinghiera,
    FotoMuratura    
}

const priceTop = (rata, totale, tan = "7.71%") => <>
<p className="mb-0">A partire da</p>
<p className="f14 mb-0 mt-2 d-flex align-items-baseline"><b>24 rate </b> &nbsp; mensili da <span className="f24 bold"> &nbsp; {rata} €*</span></p>
<p className="f14 mb-0 bold">TAN 7.50% - TAEG {tan}</p>
<div className="my-2" aria-hidden={true}><DsyDivider /></div>
<p className="f12 my-0 d-flex justify-content-between">
    <div>Totale <b>{totale} €</b></div>
    <div>IVA Inclusa</div>
</p>
<p className="f12">Prodotto disponibile solo con <br /> finanziamento tramite finanziaria</p>

</> 

const priceBottom = (rata, totale, tan = "7.71%") =>  <div className={"mt-3"} >
<div aria-hidden={true}><DsyDivider /></div>
<p className="f14 mb-0 mt-2"><b>24 rate </b>mensili da</p>
<p className='f24 bold mb-0'>{rata} €*</p>
<p className="f14 mb-0 bold">TAN 7.50% - TAEG {tan}</p>
<p className="f12 my-0">Totale <b>{totale} €</b></p>
<p className="mt-2 f12">IVA inclusa</p>
<p className="f12">Prodotto disponibile solo con finanziamento tramite finanziaria</p>
</div> 

function BoxComponent({title, serie, description, tags, image, disabled, extraClass, extraTitleCell, children, bottomChildren, topChildren, showDescription}) {
    let extraTitle = ""
    const [show, setShow] = useState(false);   
    const data = useSelector(get);
    const showRate = process.env.REACT_APP_RATE === "true"

    if (title.includes("Plug")) {
        if(data["TipologiaBalconi"] === "Muratura") 
        {
            serie = "Fotovoltaicoda parete / balcone in muratura"
            description = "<b>Ideale per chi desidera un impianto fotovoltaico, ma vive in un appartamento.</b> Semplice e veloce da installare sul tuo <b>balcone in muratura</b>, sotto la tua finestra oppure su una tua parete, <b>si connette alla rete tramite una spina elettrica</b> con una presa di corrente dedicata che rispetta i requisiti espressi dalla norma CEI 0-21, <b>permettendo un immediato risparmio sui consumi elettrici della casa.</b>"
            image = "FotoMuratura"
        }
        else {
            serie = "Fotovoltaico da balcone con ringhiera"
            description = "<b>Ideale per chi desidera un impianto fotovoltaico, ma vive in un appartamento.</b> Semplice e veloce da installare sul tuo <b>balcone in muratura</b>, sotto la tua finestra oppure su una tua parete, <b>si connette alla rete tramite una spina elettrica</b> con una presa di corrente dedicata che rispetta i requisiti espressi dalla norma CEI 0-21, <b>permettendo un immediato risparmio sui consumi elettrici della casa.</b>"
            image = "FotoRinghiera"
        }
    }
    if (extraTitleCell && data[extraTitleCell] && extraTitleCell === "User_TV") {
        extraTitle = data[extraTitleCell].split(" ").pop() 
        if (extraTitle === "43\"") {
            topChildren = priceTop("22,99", "551,76")
            bottomChildren = priceBottom("22,99", "551,76")
        }
        if (extraTitle === "55\"") {
            topChildren = priceTop("29,99", "719,76")
            bottomChildren = priceBottom("29,99", "719,76")
        }
        if (extraTitle === "65\"") {
            topChildren = priceTop("35,99", "863,76", "7,70%")
            bottomChildren = priceBottom("35,99", "863,76", "7,70%")
        }
    }
    if (extraTitleCell && !data[extraTitleCell] && extraTitleCell === "User_TV") {
        topChildren = priceTop("22,99", "551,76")
        bottomChildren = priceBottom("22,99", "551,76")
    } 

    return (<>
        <Col>
        <div aria-hidden={ disabled === true} className={"box-component " + (extraClass ?? "") + (disabled ? "disabled" : "") }>
            <div className="d-flex align-items-center mb-3 justify-content-between">
                <div className="d-flex flex-column col-lg-7">
                <div className="d-flex">
                {image && <img src={images[image]} className="me-3 d-none d-lg-block" alt="" role="presentation" />}
                <div className={topChildren ? "flex-fill" :"w-100"}> 
                {image && <div className="mb-3 d-flex justify-content-center w-100" style={{ background: "#F7F8FB", borderRadius: "0.5rem"}}>
                    <img src={images[image]} className="me-3 d-block d-lg-none w-100" alt="" role="presentation" /> 
                </div>}
                    
                <div className="title">{title} {extraTitle}</div>
                <div className="serie" dangerouslySetInnerHTML={{ __html: serie}}></div>
                {tags &&<div className={"tags" + (showDescription ? " mb-0" : "") }>
                    {tags.map((tag, i) => 
                        <div key={i} className="tag">{tag}</div>
                    )}
                </div>}
                </div>
                </div>
                { title === "Solare Virtuale" && <p className={"description d-none d-lg-block mt-4"} dangerouslySetInnerHTML={{ __html: description}}></p> }
                </div>
                {showRate && <div className="d-none d-lg-block text-end"> {topChildren} </div>}
            </div>
            { title !== "Solare Virtuale" &&<p className={showDescription ? "description" : "description mb-0 d-none d-lg-block"} dangerouslySetInnerHTML={{ __html: description}}></p> }
       
            {showRate && <div className="d-lg-none"> {bottomChildren} </div>}
            <GriButton className="d-lg-none w-100 bg-secondary" 
                onClick={() => setShow(true)} 
                variant="link"
                label={"Apri scheda dettagli prodotto"} >Dettagli prodotto</GriButton>
        </div>
        </Col>
        <ProductModalComponent 
            show={show}
            chiudi={() => setShow(false)}
            extraTitle={title}
            serie={serie}
            tags={tags}
            description={description}
            image={images[image]}
        />
        </>
    );
}

export default BoxComponent;