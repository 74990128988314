import {soldi} from "./page2Images"
import {clamp, traslateStyle} from "./bannerUtils"

const pileMin = 1;
const pileMax = 3;
const banknote = 100;
const stepsMax = 10;
const stepsMin = 1;
const pileCap = 999;

export default function CashPileComponent ({energyType, amount, style}) {
    if (!energyType || !soldi[energyType]) {
        return []
    }

    if (amount === 0) {
        return [
            <img src={soldi[energyType].grey} style={style} role="presentation" alt=""/>
        ]
    }

    if (amount === null || amount === undefined) {
        return [
            <img src={soldi[energyType].unknown} style={style} role="presentation" alt=""/>
        ]
    }


    const piles = [];
    const nPiles = clamp(((amount / banknote) / stepsMax), pileMin, pileMax);

    for (let i = 0; i < nPiles; i++) {
        const key = parseInt(clamp((amount - (i * pileCap)) / banknote, stepsMin, stepsMax))
        const icon = soldi[energyType][key]
        const newStyle = traslateStyle(style, i * 8);
        piles.push(<img src={icon} style={newStyle} role="presentation" alt=""/>)
    }
    return piles;


    

}